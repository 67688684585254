export enum AppDropdownOptionForModal {
    'Cattle Finance Manager' = 'Cattle Finance Manager',
    'Feedlot Manager' = 'Feedlot Manager',
    'Cattle PO' = 'Cattle PO',
    'Feedlot Manager + Cattle PO' = 'Feedlot Manager + Cattle PO',
    'Global' = 'Global',
}

export enum AppDropdownOptionForFilter {
    'Select App' = 'Select App',
    'Cattle Finance Manager' = 'Cattle Finance Manager',
    'Feedlot Manager' = 'Feedlot Manager',
    'Shipping Tracker' = 'Cattle PO',
}
