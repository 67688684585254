import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFinanceManagerFeatures } from './interface';

export const financeManagerFeatures: IFinanceManagerFeatures = {
    isFA201AutoSaveFeedBills: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA201_AUTO_SAVE_FEEDBILLES,
    ),
    isFA464UseDayjsInForm: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA446_USE_DAYJS_IN_FORM,
    ),
    isFA463UseDayjsInCalculation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA463_USE_DAYJS_IN_CALCULATION_FILE,
    ),
    isFA462UseDayjsInReports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA462_USE_DAYJS_IN_Reports,
    ),
    isFA633DateValidation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA633_DATE_VALIDATION_IN_FORMS,
    ),
    isFA670RelotProdAlert: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA670_RELOT_PROD_ALERT,
    ),
    isFA640FeedlotSearchDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA640_FEEDLOT_SEARCH_DROPDOWN,
    ),
    isFA641AddFeedBillButton: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA641_ADD_FEEDBILL_BUTTON_IN_FEEDBILL_PAGE,
    ),
    isFA437TenantSetting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA437_TENANT_SETTING,
    ),
    isFA654ClosedOutReportInfiniteLoading: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA654_CLOSED_OUT_PAGE_INFINI_LOADING,
    ),
    isFA437TenantSettingForFeedlot: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA437_TENANT_SETTING_FOR_FEEDLOT,
    ),
    isFA646DuplicateIssueInFeedbill: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA646_DUPLICATE_ISSUE_IN_FEEDBILL,
    ),
    isFA692CattleSaleButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA692_CATTLE_SALE_BUTTON_IN_LOT_PAGE,
    ),
    isNewIconImplement: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_NEW_ICON_IMPLEMENTATION,
    ),
    isFA698LotBalanceReportSorting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA698_LOT_BALANCE_REPORT_SORTING,
    ),
    isFA636ClosedLotOrdering: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA636_CLOSED_LOT_ORDERING,
    ),
};
