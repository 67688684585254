import { BaseOdataQueryApiArg } from '../rootApi';
import { baseUsersApi, GetApiTmUsersOdataApiResponse } from './baseUsersApi';

export const usersApi = baseUsersApi
    .injectEndpoints({
        endpoints: build => ({
            getApiTmUsersOdataInfScroll: build.query<
                GetApiTmUsersOdataApiResponse,
                UsersOdataQueryApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/users/odata`,
                    params: {
                        include: queryArg.include,
                        $filter: queryArg.filter,
                        $orderBy: queryArg.orderBy,
                        $top: queryArg.top,
                        $skip: queryArg.skip,
                        includeDisabled: queryArg.includeDisabled,
                        role: queryArg.role,
                    },
                }),
                serializeQueryArgs: ({ queryArgs, endpointName }) => {
                    const newQueryArgs = {
                        ...queryArgs,
                        top: undefined,
                        skip: undefined,
                    };
                    return newQueryArgs;
                },
                // Always merge incoming data to the cache entry
                merge: (currentCache, newItems, { arg }) => {
                    if (arg.skip === 0) {
                        return newItems;
                    } else {
                        currentCache.push(...newItems);
                    }
                },
                forceRefetch({ currentArg, previousArg }) {
                    return currentArg !== previousArg;
                },
                // currently we do not have a way of making tags work with infinite scroll. If we use tags and a tag is invalidated this api call will be triggered. When method merge is called as a part of the api call, the newly fetched entries are added to the end of the list. These entries are duplicates due to 'skip' param not changing.
                // providesTags: ['Users'],
            }),
            getApiTmUsersOdata: build.query<
                GetApiTmUsersOdataApiResponse,
                UsersOdataQueryApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/users/odata`,
                    params: {
                        include: queryArg.include,
                        $filter: queryArg.filter,
                        $orderBy: queryArg.orderBy,
                        $top: queryArg.top,
                        $skip: queryArg.skip,
                        includeDisabled: queryArg.includeDisabled,
                        role: queryArg.role,
                    },
                }),
                providesTags: ['Users'],
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: [],
        endpoints: {
            putApiTmUsersById: {
                invalidatesTags: (_, error, arg) =>
                    !error ? [{ type: 'Users', id: arg.id }] : [],
            },
        },
    });

export interface UsersOdataQueryApiArg extends BaseOdataQueryApiArg {
    include?: string;
    includeDisabled?: boolean;
    role?: string;
}
