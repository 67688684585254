import { AppDropdownOptionForFilter } from './appDropdownOption';

export enum AppScope {
    'APP:FeedlotManager' = 'APP:FeedlotManager',
    'APP:CattleFinanceManager' = 'APP:CattleFinanceManager',
    'APP:ShippingTracker' = 'APP:ShippingTracker',
}

export const AppScopeToAppDropdownOptionForFilter: Record<
    AppScope,
    AppDropdownOptionForFilter
> = {
    [AppScope['APP:FeedlotManager']]:
        AppDropdownOptionForFilter['Feedlot Manager'],
    [AppScope['APP:CattleFinanceManager']]:
        AppDropdownOptionForFilter['Cattle Finance Manager'],
    [AppScope['APP:ShippingTracker']]:
        AppDropdownOptionForFilter['Shipping Tracker'],
};

enum AppFilterToAppScope {
    'Feedlot Manager' = 'Feedlot Manager',
    'Cattle Finance Manager' = 'Cattle Finance Manager',
    'Shipping Tracker' = 'Cattle PO',
}

export const AppDropdownToAppScopeForDatabase: Record<
    AppFilterToAppScope,
    AppScope
> = {
    [AppFilterToAppScope['Feedlot Manager']]: AppScope['APP:FeedlotManager'],
    [AppFilterToAppScope['Cattle Finance Manager']]:
        AppScope['APP:CattleFinanceManager'],
    [AppFilterToAppScope['Shipping Tracker']]: AppScope['APP:ShippingTracker'],
};
