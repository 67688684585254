import { FC } from 'react';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13054On_NgatFmNewLogoIcon } = feedlotManagerFeatures;

const SidebarLogoNGAT: FC = props => {
    return (
        <img
            {...props}
            alt="sidebar_ngat.png"
            src={require(
                `${isCA13054On_NgatFmNewLogoIcon ? './sidebar_ngat_new.png' : './sidebar_ngat.png'}`,
            )}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
        />
    );
};

export default SidebarLogoNGAT;
