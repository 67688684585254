import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
    RolesForCreateNewButtonAccess,
    SideNavItems,
} from '../../utils/constants/RoleConstants';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';

const SideNavFinancial = () => {
    const { accessHandler, isAuthenticated, getUsersRoles } = useAuthContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const handleClick = (route: string) => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            dispatch(setRoute(route));
        } else {
            navigate(route);
        }
    };

    return (
        <>
            {isAuthenticated() &&
            accessHandler(RolesForCreateNewButtonAccess) ? (
                <Accordion className="accordion">
                    <Accordion.Item className="accordion-item" eventKey="-1">
                        <Accordion.Header className="accordion-header create-button">
                            {
                                SideNavItems.financialSideNavItem
                                    ?.createButtonLabel
                            }
                        </Accordion.Header>
                        <Accordion.Body className="accordion-body">
                            {SideNavItems.financialSideNavItem?.createButtonObj?.map(
                                (item, index) => {
                                    return (
                                        <Accordion.Item
                                            key={index}
                                            className={
                                                'accordion-subitem pt-3 px-3'
                                            }
                                            eventKey={index.toString()}
                                        >
                                            <span
                                                key={index}
                                                onClick={() =>
                                                    handleClick(
                                                        item.redirectUrl,
                                                    )
                                                }
                                                className="sideNav"
                                            >
                                                {item.label}
                                            </span>
                                        </Accordion.Item>
                                    );
                                },
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : null}
            {isAuthenticated() && (
                <>
                    {SideNavItems.financialSideNavItem?.subFinanceItems?.map(
                        (item, index) => {
                            let component: JSX.Element | null = null;
                            for (let i = 0; i < item.roles.length; i++) {
                                if (getUsersRoles().includes(item.roles[i])) {
                                    component = (
                                        <Accordion
                                            key={index}
                                            className="accordion-secondary"
                                        >
                                            <Accordion.Item
                                                eventKey={index.toString()}
                                            >
                                                <span
                                                    onClick={() =>
                                                        handleClick(
                                                            item.redirectUrl,
                                                        )
                                                    }
                                                >
                                                    <Accordion.Header>
                                                        {item.icon} {item.label}
                                                    </Accordion.Header>
                                                </span>
                                            </Accordion.Item>
                                        </Accordion>
                                    );
                                    break;
                                }
                            }
                            return component;
                        },
                    )}
                </>
            )}
        </>
    );
};

export default SideNavFinancial;
