import { ChangeEvent } from 'react';

export interface IRationInitialState {
    loading: boolean;
    isRationDetailChanged: boolean;
    rationDetail: IRationDetail;
    rationErrorDetail: IRationErrorDetail;
    ingredients: IIngredients[];
    rationIngredients: IRationIngredient[];
    rationIngredientsError: IRationIngredientError[];
    ingredientTotalAmount: number;
    ingredientTotalPercentage: number;
    dryMatterTotal: number;
    allRations: IRationDetail[];
    submitPendingCreateRation: boolean;
    activeButtonConfirmationModal: BackdateRationMarkupConfirmationOptions;
}

export enum BackdateRationMarkupConfirmationOptions {
    Yes = 'Yes',
    No = 'No',
    NoneSelected = 'None Selected',
}

export type ISetIngredientsField = (
    fieldName: string,
    fieldValue: string | number | Date | null,
    index: number,
    type?: string,
) => void;

export interface IRationFooter {
    onCancel: () => void;
    onSave: () => void;
    isCommentChanged: boolean;
}

export interface IRationCalculatorModal {
    show: boolean;
    onHide: () => void;
    rations: IRation[];
}

export interface IBackdateRationMarkupModal {
    show: boolean;
    rations: IRation[];
    onHide: () => void;
}

export interface IRationMarkupHistoryTable {
    rationId?: number;
}

export interface IRationIngredientComponent {
    index: number;
    setIngredientsField: ISetIngredientsField;
    filteredIngredients: IIngredients[];
    pushRationIngredient: () => void;
    popRationIngredient: (index: number) => void;
}

export interface IRationFormBasicDetail {
    handleChangeRationDetail: (
        e: ChangeEvent<HTMLInputElement>,
        type?: string,
    ) => void;
    isNewRation: boolean;
}

export interface IEditRationAdditionalFields {
    handleChangeRationDetail: (
        e: ChangeEvent<HTMLInputElement>,
        type?: string,
    ) => void;
    isNewRation: boolean;
}

export interface IRationDetail {
    rationId: number;
    name: string;
    categoryId: number;
    lastEffectiveDate?: string;
    lastEffectiveTime?: string;
    prevEffectiveDate?: string;
    effectiveDate: string;
    loadSize: string;
    comments: string;
    makeCurrentRation: boolean;
    markUp: number | string;
    nem: string;
    neg: string;
    corporatePrice: string;
    customPrice: string;
    rationVersion: string | number;
    rationVersionId: number;
    rationIngredients?: IRationIngredient[];
    costPerLbWithMarkup?: number;
}

export interface IRationErrorDetail {
    name: boolean;
    categoryId: boolean;
    effectiveDate: boolean;
    loadSize: boolean;
    nem: boolean;
    neg: boolean;
}

export interface IRationTable {
    ration: IRation[];
    loading: boolean;
    canCallApi: boolean;
    filter: IRationFilter;
    filterHandler: (
        filterFieldName: string,
        filterFieldValues: string | number,
    ) => void;
    onChangeRationVersion: (
        fieldName: string,
        fieldValue: string,
        index: number,
    ) => void;
}

export interface IRation {
    lastChanged: string;
    name: string;
    rationId: number;
    currentRationVersionId: number;
    rationVersions: { id: number; name: string }[];
}

export interface IRationFilter {
    include: string;
    limit: number;
    lotId: number | string;
    offset: number;
}

export interface IRationVersions {
    rationVersionId: number;
    version: number;
    rationName: string;
    effectiveDate?: string;
    markUp?: number;
    modifiedBy?: string;
    modifiedDateTime?: string;
}

export interface IRationResponse {
    rationId: number;
    name: string;
    lastChanged: string;
    currentRationVersionId: number;
    rationVersions: IRationVersions[];
}

export interface IRationIngredientError {
    commodityId: boolean;
    amountPercentage: boolean;
    dryMatterPercentage: boolean;
    price: boolean;
    lbsPerLoad: boolean;
}

export interface IEmptyAndNotSelectedIngredient {
    commodityId?: number;
    name: string;
    dryMatterPercentage?: number;
    id: number;
}

export interface IIngredients {
    commodityId?: number;
    name: string;
    dryMatterPercentage?: number;
    price?: number;
    id: number;
}

export interface IRationData {
    commodityId: number;
    amountPercentage: number;
    lbsPerLoad: number;
    dryMatterPercentage: number;
    price: number;
}

export interface ICommodity {
    commodityId?: number | string;
    contracts: Array<string>;
    dryMatterPercentage: number | string;
    inventoryAmount: number | string;
    lastAdjusted: string;
    name: string;
    price: number | string;
    priceEffectiveDate: string;
    status: string;
    version: number | string;
}

export interface IRationIngredientResponse {
    amountPercentage: string | number;
    commodityId: string | number;
    dryMatterPercentage?: string | number;
    id?: string | number;
    lbsPerLoad?: string | number;
    price: string | number;
    isDisable?: boolean;
    ingredients?: string;
    mixOrder?: number;
    commodity: ICommodity;
}

export interface IRationIngredient {
    amountPercentage: string | number;
    commodityId: string | number;
    dryMatterPercentage?: string | number;
    id?: string | number;
    lbsPerLoad?: string | number;
    price: string | number;
    isDisable?: boolean;
    ingredients?: string;
    mixOrder?: number;
}

export interface IRationsComponent {
    index: number;
    item: IRationIngredient[];
}

export interface IRationCalculatorPayload {
    rationId: number;
    loadSize: number;
}
export interface IRationCalculatorPayloadError {
    rationId: boolean;
    loadSize: boolean;
}

export interface IBackdateRationMarkupPayload {
    rationId: number;
    markUp: number | string;
    effectiveDate: string;
}

export type IHandleRationEffectiveDate = (
    rationDetail: IRationDetail,
    fieldName?: string,
) => void;

export type IRationFormIngredients = {
    handleRationEffectiveDate: IHandleRationEffectiveDate;
};
