import ResetPasswordIcon from '../../../../../../Assets/ResetPasswordIcon';
import { useModalStatus } from '../../../../../../commonHooks/useModalStatus';
import { IUser } from '../interfaces/UserManagementInterface';
import UserManagementResetPasswordModal from './UserManagementResetPasswordModal';

const UserManagementResetPasswordButton = ({ user }: { user: IUser }) => {
    const userManagementResetPasswordModal = useModalStatus();

    const userManagementResetPasswordModalProps = {
        showModal: userManagementResetPasswordModal.isOpen,
        hideModal: userManagementResetPasswordModal.close,
    };

    return (
        <>
            <UserManagementResetPasswordModal
                user={user}
                key={+userManagementResetPasswordModal.isOpen}
                {...userManagementResetPasswordModalProps}
            />
            {user.resetPassword && (
                <ResetPasswordIcon
                    onClick={userManagementResetPasswordModal.open}
                    className={' mx-2 cursor-pointer delete-icon'}
                />
            )}
        </>
    );
};

export default UserManagementResetPasswordButton;
