import { TenantManagementModelsUserManagementRoleModel } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import { spaceRoleName } from '../hooks/userManagementHooks';
import DeleteIcon from '../../../../../../Assets/DeleteIcon';
import { UserModelWithRequiredNames } from '../interfaces/UserManagementInterface';
import { Dispatch, SetStateAction } from 'react';
import { AppDropdownOptionForModal } from '../../../../../common/modules/auth/constants/appDropdownOption';
import {
    Roles,
    RolesToHideFromUser,
} from '../../../../../common/modules/auth/constants/roles';

const UserAccountRole = ({
    role,
    account,
    setUser,
    accountIndex,
    roleIndex,
}: {
    role: TenantManagementModelsUserManagementRoleModel;
    account: string;
    setUser: Dispatch<SetStateAction<UserModelWithRequiredNames | undefined>>;
    accountIndex: number;
    roleIndex: number;
}) => {
    const deleteRole = () => {
        setUser(prevState => {
            if (!prevState?.accounts) {
                return prevState;
            } else {
                const updatedAccounts = [...prevState.accounts];
                const accountToUpdate = { ...updatedAccounts[accountIndex] };
                accountToUpdate.roles =
                    accountToUpdate.roles?.filter((_, i) => i !== roleIndex) ||
                    [];
                updatedAccounts[accountIndex] = accountToUpdate;
                return {
                    ...prevState,
                    accounts: updatedAccounts,
                };
            }
        });
    };

    return !role.name ||
        RolesToHideFromUser.includes(role.name as Roles) ? null : (
        <>
            <tr className="row flex-row d-flex mx-0">
                <td className="col-md-3">
                    {AppDropdownOptionForModal['Feedlot Manager']}
                </td>
                <td className="col-md-3">{account}</td>
                <td className="col-md-3">{spaceRoleName(role.name ?? '')}</td>
                <td className={`col-md-3 cursor-pointer text-center`}>
                    <DeleteIcon onClick={deleteRole} />
                </td>
            </tr>
        </>
    );
};

export default UserAccountRole;
