import { TenantManagementModelsUserManagementRoleModel } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import { UserModelWithRequiredNames } from '../interfaces/UserManagementInterface';
import { spaceRoleName } from '../hooks/userManagementHooks';
import DeleteIcon from '../../../../../../Assets/DeleteIcon';
import { Dispatch, SetStateAction } from 'react';
import {
    Roles,
    RolesAndScopesByAppDropdownOptionForModal,
    RolesToHideFromUser,
} from '../../../../../common/modules/auth/constants/roles';
import { AppDropdownOptionForModal } from '../../../../../common/modules/auth/constants/appDropdownOption';

const UserGeneralRole = ({
    role,
    setUser,
    index,
}: {
    role: TenantManagementModelsUserManagementRoleModel;
    setUser: Dispatch<SetStateAction<UserModelWithRequiredNames | undefined>>;
    index: number;
}) => {
    const roleName: Roles | null =
        role.name && role.name in Roles ? (role.name as Roles) : null;

    const appDropdownOptionForModal: AppDropdownOptionForModal | null =
        getAppDropdownOptionForModal(roleName);

    const deleteRole = () => {
        setUser(prevState => {
            if (!prevState) return prevState;
            const updatedRoles =
                prevState.roles?.filter((_, i) => i !== index) || [];
            return {
                ...prevState,
                roles: updatedRoles,
            };
        });
    };

    return !role.name ||
        RolesToHideFromUser.includes(role.name as Roles) ? null : (
        <>
            <tr className="row flex-row d-flex mx-0">
                <td className="col-md-3">{appDropdownOptionForModal}</td>
                <td className="col-md-3">{'--'}</td>
                <td className="col-md-3">{spaceRoleName(role.name ?? '')}</td>
                <td className={`col-md-3 cursor-pointer text-center`}>
                    <DeleteIcon onClick={deleteRole} />
                </td>
            </tr>
        </>
    );
};

const getAppDropdownOptionForModal = (
    roleName: Roles | null,
): AppDropdownOptionForModal | null => {
    let appDropdownOptionForModal: AppDropdownOptionForModal | null = null;

    Object.entries(RolesAndScopesByAppDropdownOptionForModal).forEach(
        ([appDropdownOption, { roles }]) => {
            if (
                roleName &&
                roles.includes(roleName) &&
                appDropdownOption in AppDropdownOptionForModal
            ) {
                appDropdownOptionForModal =
                    appDropdownOption as AppDropdownOptionForModal;
            }
        },
    );

    return appDropdownOptionForModal;
};

export default UserGeneralRole;
