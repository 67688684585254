import { Form } from 'react-bootstrap';
import DeleteIcon from '../../../../../../Assets/DeleteIcon';
import { Dispatch, SetStateAction } from 'react';
import { getApiTmAccountsResponse } from '../../../../../../Redux/Apis/Accounts/accountsApi';
import {
    IAdditionalRole,
    UserModelWithRequiredNames,
} from '../interfaces/UserManagementInterface';
import { deepClone } from '../../../../../../helpers';
import {
    spaceRoleName,
    useGetDataForFilterDropdowns,
} from '../hooks/userManagementHooks';
import {
    Roles,
    RolesAndScopesByAppDropdownOptionForModal,
} from '../../../../../common/modules/auth/constants/roles';
import { AppDropdownOptionForModal } from '../../../../../common/modules/auth/constants/appDropdownOption';

const AddRole = ({
    appsForDropdown,
    accounts,
    additionalRoles,
    setAdditionalRoles,
    user,
}: {
    appsForDropdown: AppDropdownOptionForModal[];
    accounts: getApiTmAccountsResponse[];
    additionalRoles: IAdditionalRole[];
    setAdditionalRoles: Dispatch<SetStateAction<IAdditionalRole[]>>;
    user: UserModelWithRequiredNames;
}) => {
    const { isSuperAdmin } = useGetDataForFilterDropdowns().editingPrivileges;

    const getRolesForSelectedApp = (
        app: string,
        accountId: number,
        currentSelectedRole: string,
    ): string[] => {
        if (!(app in AppDropdownOptionForModal)) {
            return [];
        }
        const appDropdownOptionForModal: AppDropdownOptionForModal =
            app as AppDropdownOptionForModal;

        const rolesForApp: Roles[] =
            RolesAndScopesByAppDropdownOptionForModal[appDropdownOptionForModal]
                .roles ?? [];
        let userRolesToExclude: string[] = [];
        let additionalRolesToExclude: string[] = [];

        if (
            appDropdownOptionForModal ===
                AppDropdownOptionForModal['Feedlot Manager'] &&
            accountId
        ) {
            userRolesToExclude =
                user.accounts
                    ?.find(account => account.accountId === accountId)
                    ?.roles?.map(role => role.name ?? '') ?? [];
            additionalRolesToExclude = additionalRoles
                .filter(
                    role =>
                        role.accountId === accountId &&
                        role.role !== currentSelectedRole,
                )
                .map(role => role.role);
        } else {
            userRolesToExclude =
                user?.roles?.map(role => role.name ?? '') ?? [];
            additionalRolesToExclude = additionalRoles
                .map(role => role.role)
                .filter(role => role !== currentSelectedRole);
        }

        const rolesToExclude = [
            ...userRolesToExclude,
            ...additionalRolesToExclude,
        ];

        !isSuperAdmin && rolesToExclude.push(Roles.SuperAdmin);

        const filteredRoles: Roles[] = rolesForApp.filter(
            role => !rolesToExclude.includes(role),
        );

        return ['Select a Role', ...filteredRoles];
    };

    const appDropdownOptions = ['Select an App', ...appsForDropdown] as const;

    const changeApp = (value: string, index: number) => {
        const updatedRoles = deepClone(additionalRoles);
        updatedRoles[index] = {
            ...updatedRoles[index],
            app: value,
            accountId: 0,
            role: 'Select a Role',
        };
        setAdditionalRoles(updatedRoles);
    };

    const changeAccount = (value: number, index: number) => {
        const updatedRoles = deepClone(additionalRoles);
        updatedRoles[index] = {
            ...updatedRoles[index],
            accountId: value,
            role: 'Select a Role',
        };
        setAdditionalRoles(updatedRoles);
    };

    const changeRole = (value: string, index: number) => {
        const updatedRoles = deepClone(additionalRoles);
        updatedRoles[index] = {
            ...updatedRoles[index],
            role: value,
        };
        setAdditionalRoles(updatedRoles);
    };

    const deleteRole = (index: number) => {
        const updatedRoles = deepClone(additionalRoles);
        updatedRoles.splice(index, 1);
        setAdditionalRoles(updatedRoles);
    };

    return (
        <>
            {additionalRoles.map((newRole, index) => (
                <tr className="row flex-row d-flex mx-0" key={index}>
                    <td className="col-md-3">
                        <Form.Control
                            className={`text-secondary form-item border border-rounded`}
                            as="select"
                            size="sm"
                            name="app"
                            value={newRole.app}
                            onChange={e => changeApp(e.target.value, index)}
                        >
                            {appDropdownOptions.map((app, index) => (
                                <option key={index} value={app}>
                                    {app}
                                </option>
                            ))}
                        </Form.Control>
                    </td>
                    <td className="col-md-3">
                        <Form.Control
                            className={`text-secondary form-item border border-rounded`}
                            as="select"
                            size="sm"
                            name="accountId"
                            value={newRole.accountId}
                            disabled={
                                newRole.app !==
                                AppDropdownOptionForModal['Feedlot Manager']
                            }
                            onChange={e =>
                                changeAccount(+e.target.value, index)
                            }
                        >
                            <option key={0} value={0}>
                                {'Select a Feedlot'}
                            </option>
                            {accounts.map(account => (
                                <option
                                    key={account.accountId}
                                    value={account.accountId}
                                >
                                    {account.name}
                                </option>
                            ))}
                        </Form.Control>
                    </td>
                    <td className="col-md-3">
                        <Form.Control
                            className={`text-secondary form-item border border-rounded`}
                            as="select"
                            size="sm"
                            disabled={newRole.app === 'Select an App'}
                            name="role"
                            value={newRole.role}
                            onChange={e => changeRole(e.target.value, index)}
                        >
                            {getRolesForSelectedApp(
                                newRole.app,
                                newRole.accountId,
                                newRole.role,
                            ).map((role, index) => (
                                <option key={index} value={role}>
                                    {spaceRoleName(role)}
                                </option>
                            ))}
                        </Form.Control>
                    </td>
                    <td className={`col-md-3 cursor-pointer text-center`}>
                        <DeleteIcon onClick={() => deleteRole(index)} />
                    </td>
                </tr>
            ))}
        </>
    );
};

export default AddRole;
