import { Modal, Button } from 'react-bootstrap';
import { useLanguage } from '../../../commonHooks/useLocalization';
import { CommonConstants } from '../../../utils/constants/CommonConstants';
import { FmSettingsConstant } from '../../../utils/constants/FmSettingsConstants';
import { IDeleteItemModal } from '../../../utils/Interface/MixingBinInterface';
import { feedlotManagerFeatures } from '../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13076On_OwnerActiveCheckboxFix } = feedlotManagerFeatures;

export const DeleteItemModal = ({
    item,
    handleDeleteItem,
    showModal,
    itemName,
}: IDeleteItemModal) => {
    const language = useLanguage();

    return (
        <Modal
            className="px-2 pt-5"
            show={showModal}
            centered
            animation
            onHide={() => handleDeleteItem(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {(item.isFeedTaskAssociated || item.isAssociated) &&
                            (item.active ||
                            (isCA13076On_OwnerActiveCheckboxFix &&
                                item.isActive)
                                ? FmSettingsConstant.deleteConfirmationModal
                                      .header.associationHeader
                                : FmSettingsConstant.deleteConfirmationModal
                                      .header.importantNoticeHeader)}
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="preline-text">
                {item.isFeedTaskAssociated || item.isAssociated
                    ? (item.active ||
                          (isCA13076On_OwnerActiveCheckboxFix &&
                              item.isActive)) &&
                      itemName !== 'Pen'
                        ? itemName === 'TruckingCompany'
                            ? FmSettingsConstant.deleteConfirmationModal.message
                                  .associationMessageForCompany
                            : FmSettingsConstant.deleteConfirmationModal.message.associationMessage.replaceAll(
                                  '{Item name}',
                                  itemName,
                              )
                        : itemName === 'Pen'
                          ? FmSettingsConstant.deleteConfirmationModal.message
                                .importantNoticeMessageForPen
                          : FmSettingsConstant.deleteConfirmationModal.message.importantNoticeMessage.replaceAll(
                                '{Item name}',
                                itemName,
                            )
                    : FmSettingsConstant.deleteConfirmationModal.message
                          .confirmationMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="secondary"
                    variant="secondary"
                    onClick={() => handleDeleteItem(false)}
                >
                    {itemName === 'Pen'
                        ? item.isFeedTaskAssociated || item.isAssociated
                            ? CommonConstants[language.value].ok
                            : CommonConstants[language.value].cancel
                        : (item.isFeedTaskAssociated || item.isAssociated) &&
                            !(
                                item.active ||
                                (isCA13076On_OwnerActiveCheckboxFix &&
                                    item.isActive)
                            )
                          ? CommonConstants[language.value].ok
                          : CommonConstants[language.value].cancel}
                </Button>

                {itemName === 'Pen' ? (
                    !(item.isFeedTaskAssociated || item.isAssociated) && (
                        <Button
                            variant="secondary"
                            onClick={() => handleDeleteItem(true)}
                        >
                            {CommonConstants[language.value].delete}
                        </Button>
                    )
                ) : (item.isFeedTaskAssociated || item.isAssociated) &&
                  !(
                      item.active ||
                      (isCA13076On_OwnerActiveCheckboxFix && item.isActive)
                  ) ? null : (
                    <Button
                        variant="secondary"
                        onClick={() => handleDeleteItem(true)}
                    >
                        {item.isFeedTaskAssociated || item.isAssociated
                            ? CommonConstants[language.value].deactivate
                            : CommonConstants[language.value].delete}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
