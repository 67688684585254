import { useState, useEffect, ReactElement } from 'react';

import SideNavTab from './sidenav/SideNavTab';
import SideNavBuyPO from './sidenav/SideNavBuyPO';
import SideNavFM from './sidenav/SideNavFM';
import SideNavFinancial from './sidenav/SideNavFinancial';
import SidebarLogoBuyPo from '../Assets/sidebarlogos/SidebarLogoBuyPo';
import SidebarLogoFinancial from '../Assets/sidebarlogos/SidebarLogoFinancial';
import SidebarLogoNGAT from '../Assets/sidebarlogos/SidebarLogoNGAT';
import SidebarLogoFM from '../Assets/sidebarlogos/SidebarLogoFM';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import {
    IGenericSetState,
    sideNavTabs,
} from '../utils/Interface/CommonInterface';
import { useLocation } from 'react-router-dom';
import {
    setSideNavHidden,
    setSelectedSideNavTab,
} from '../Redux/Reducer/common';
import Constants from '../utils/Constants';
import { commonFeatures } from '../apps/common/featureFlags/commonFeatures';

const { isCA9685_localAuthLoginScreen } = commonFeatures;

function SideNav({
    sideNavTab,
    setSideNavTab,
}: {
    sideNavTab: string | number;
    setSideNavTab: IGenericSetState<string | number>;
}) {
    const { isAuthenticated } = useAuthContext();
    const [sideNavLogoComponent, setSideNavLogoComponent] =
        useState<ReactElement>();
    const [sideNavMenuComponent, setSideNavMenuComponent] =
        useState<ReactElement>();

    const { isSideNavHidden } = useAppSelector(state => state.common);

    const dispatch = useAppDispatch();

    const handleClick = event => {
        isSideNavHidden
            ? dispatch(setSideNavHidden(false))
            : dispatch(setSideNavHidden(true));
    };
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname === '/feedcall' ||
            location.pathname === Constants.Routes.NEWFEEDCALLV1DOT1 ||
            location.pathname ===
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS ||
            location.pathname ===
                Constants.Routes.CREATE_OUTGOING_SCALE_TICKETS ||
            location.pathname === Constants.Routes.REALIZER_HISTORY ||
            location.pathname.includes(
                `${Constants.Routes.UPDATE_SHIP_CATTLE_REALIZER}`,
            ) ||
            location.pathname === '/treatments/addtreatment' ||
            location.pathname.includes('/treatments/updatetreatment/')
        ) {
            dispatch(setSideNavHidden(true));
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        if (!isCA9685_localAuthLoginScreen) {
            const getSideNavLogoAndMenu = () => {
                let logoComponent = <SidebarLogoNGAT />;
                let menuComponent = <></>;
                if (sideNavTab === 'buyPo') {
                    dispatch(setSelectedSideNavTab(sideNavTabs.BuyPo));
                    logoComponent = <SidebarLogoBuyPo />;
                    menuComponent = <SideNavBuyPO />;
                } else if (sideNavTab === 'financial') {
                    dispatch(setSelectedSideNavTab(sideNavTabs.Financial));
                    logoComponent = <SidebarLogoFinancial />;
                    menuComponent = <SideNavFinancial />;
                } else if (sideNavTab === 'fm') {
                    dispatch(setSelectedSideNavTab(sideNavTabs.Fm));
                    logoComponent = <SidebarLogoFM />;
                    menuComponent = <SideNavFM />;
                }
                setSideNavLogoComponent(logoComponent);
                setSideNavMenuComponent(menuComponent);
            };
            getSideNavLogoAndMenu();
        }
    }, [sideNavTab]);

    const logoMap = {
        buyPo: <SidebarLogoBuyPo />,
        financial: <SidebarLogoFinancial />,
        fm: <SidebarLogoFM />,
        default: <SidebarLogoNGAT />,
    };

    const menuMap = {
        buyPo: <SideNavBuyPO />,
        financial: <SideNavFinancial />,
        fm: <SideNavFM />,
        default: <></>,
    };

    const logoComponent = logoMap[sideNavTab] || logoMap.default;
    const menuComponent = menuMap[sideNavTab] || menuMap.default;

    return (
        <>
            {isAuthenticated() ? (
                <>
                    <nav
                        className={
                            isSideNavHidden
                                ? 'd-md-active sidenav sidebar-shrink'
                                : 'd-md-active sidenav'
                        }
                    >
                        {isCA9685_localAuthLoginScreen ? (
                            <>
                                <div className="sidebar-logo">
                                    {logoComponent}
                                </div>
                                <SideNavTab setSideNavTab={setSideNavTab} />
                                {menuComponent}
                            </>
                        ) : (
                            <>
                                <div className="sidebar-logo">
                                    {sideNavLogoComponent}
                                </div>
                                <SideNavTab setSideNavTab={setSideNavTab} />
                                {sideNavMenuComponent})
                            </>
                        )}
                    </nav>
                    <div
                        className={
                            isSideNavHidden
                                ? 'sidenav-caret caret-slide-left d-md-block d-none'
                                : 'sidenav-caret d-md-block d-none'
                        }
                        onClick={handleClick}
                    >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <i className="fas fa-caret-left"></i>
                        </a>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default SideNav;
