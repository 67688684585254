import {
    AppDropdownOptionForModal,
    AppDropdownOptionForFilter,
} from './appDropdownOption';
import { AppScope } from './appScope';

export enum Roles {
    AppAdmin = 'AppAdmin',
    // Owner = 'Owner',//owner will be enabled when we remove super admin
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin',
    Manager = 'Manager',
    User = 'User',
    OfficeManager = 'OfficeManager',
    Finance = 'Finance',
    WarehousingApp = 'WarehousingApp',
    Buyer = 'Buyer',
    TruckingCompany = 'TruckingCompany',
    FeedlotManager = 'FeedlotManager',
    //InvoiceManager = 'InvoiceManager',
    HealthManager = 'HealthManager',
    Cowboy = 'Cowboy',
    Operator = 'Operator',
    FeedTruckDriver = 'FeedTruckDriver',
    AccountOwner = 'AccountOwner',
    AccountAdmin = 'AccountAdmin',
    AccountManager = 'AccountManager',
    AccountUser = 'AccountUser',
    AccountStakeholder = 'AccountStakeholder',
}

const CattleFinanceManagerRoles: Roles[] = [
    Roles.Finance,
    Roles.WarehousingApp, //in the future this should be a tenant setting for finance app instead of a user role
] as const;

const AccountRoles: Roles[] = [
    Roles.FeedlotManager,
    // Roles.InvoiceManager,
    Roles.HealthManager,
    Roles.Cowboy,
    Roles.Operator,
    Roles.FeedTruckDriver,
    // Roles.ACCOUNT_OWNER,//these AccountRole roles exist on the BE but are not used
    // Roles.ACCOUNT_ADMIN,
    // Roles.ACCOUNT_MANAGER,
    // Roles.ACCOUNT_USER,
    // Roles.ACCOUNT_STAKEHOLDER,
] as const;

const FeedlotManagerRoles: Roles[] = AccountRoles;

const ShippingTrackerRoles: Roles[] = [
    Roles.Buyer,
    Roles.TruckingCompany,
] as const;

const FeedlotManagerAndCattlePORoles: Roles[] = [Roles.OfficeManager] as const;

const GlobalRoles: Roles[] = [
    // Roles.APP_ADMIN,
    // Roles.Owner,//owner will be enabled when we remove super admin
    Roles.SuperAdmin, //will be replaced with Owner
    Roles.Admin,
    // Roles.MANAGER,
] as const;

export const RolesAndScopesByAppDropdownOptionForModal: Record<
    AppDropdownOptionForModal,
    { roles: Roles[]; appScopes: AppScope[] }
> = {
    'Cattle Finance Manager': {
        roles: CattleFinanceManagerRoles,
        appScopes: [AppScope['APP:CattleFinanceManager']],
    },
    'Feedlot Manager': {
        roles: FeedlotManagerRoles,
        appScopes: [AppScope['APP:FeedlotManager']],
    },
    'Cattle PO': {
        roles: ShippingTrackerRoles,
        appScopes: [AppScope['APP:ShippingTracker']],
    },
    'Feedlot Manager + Cattle PO': {
        roles: FeedlotManagerAndCattlePORoles,
        appScopes: [
            AppScope['APP:FeedlotManager'],
            AppScope['APP:ShippingTracker'],
        ],
    },
    Global: {
        roles: GlobalRoles,
        appScopes: [
            AppScope['APP:FeedlotManager'],
            AppScope['APP:ShippingTracker'],
            AppScope['APP:CattleFinanceManager'],
        ],
    },
};

export const RolesByAppDropdownOptionForFilter: Record<
    AppDropdownOptionForFilter,
    Roles[]
> = {
    [AppDropdownOptionForFilter['Feedlot Manager']]: [
        ...GlobalRoles,
        ...FeedlotManagerAndCattlePORoles,
        ...FeedlotManagerRoles,
    ],
    [AppDropdownOptionForFilter['Cattle Finance Manager']]: [
        ...GlobalRoles,
        ...CattleFinanceManagerRoles,
    ],
    [AppDropdownOptionForFilter['Shipping Tracker']]: [
        ...GlobalRoles,
        ...FeedlotManagerAndCattlePORoles,
        ...ShippingTrackerRoles,
    ],
    [AppDropdownOptionForFilter['Select App']]: [
        ...GlobalRoles,
        ...FeedlotManagerAndCattlePORoles,
        ...FeedlotManagerRoles,
        ...ShippingTrackerRoles,
        ...CattleFinanceManagerRoles,
    ],
};

export const RolesToHideFromUser: Roles[] = [Roles.User, Roles.AccountAdmin];
