import dayjs from '../../utils/dayjs';
import Constants from '../../utils/Constants';
import { useEffect, useState } from 'react';
import OnlineIcon from '../../Assets/OnlineIcon';
import OfflineIcon from '../../Assets/OfflineIcon';
import { useLocation } from 'react-router-dom';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA11014On_FMDashboard, isCA12972On_RefreshFeedStatus } =
    feedlotManagerFeatures;

const NetworkDisplay = () => {
    const [networkStatus, setNetworkStatus] = useState<string>(
        navigator.onLine
            ? Constants.networkDisplayMessage.online
            : Constants.networkDisplayMessage.offline,
    );
    const [refreshDate, setRefreshDate] = useState<string>(
        dayjs().format('MMMM D, YYYY h:mm A z'),
    );
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('offline', () => {
            setNetworkStatus(Constants.networkDisplayMessage.offline);
        });
        window.addEventListener('online', () => {
            setNetworkStatus(Constants.networkDisplayMessage.online);
        });

        return () => {
            window.removeEventListener('offline', () => {
                setNetworkStatus(Constants.networkDisplayMessage.offline);
            });
            window.removeEventListener('online', () => {
                setNetworkStatus(Constants.networkDisplayMessage.online);
            });
        };
    }, []);

    useEffect(() => {
        setRefreshDate(dayjs().format('MMMM D, YYYY h:mm A z'));
    }, [location]);

    return (
        <div>
            <div className="block-heading m-0 text-center">
                {!isCA11014On_FMDashboard && !isCA12972On_RefreshFeedStatus ? (
                    <>
                        <u>{Constants.networkDisplayMessage.lastRefreshed}</u>{' '}
                        {refreshDate}
                        {' | '}
                        <u>{Constants.networkDisplayMessage.network}</u>{' '}
                        <span
                            className={
                                networkStatus ===
                                Constants.networkDisplayMessage.online
                                    ? 'network-online'
                                    : 'network-offline'
                            }
                        >
                            {networkStatus}
                        </span>{' '}
                        <span className="network-icon">
                            {networkStatus ===
                            Constants.networkDisplayMessage.online ? (
                                <OnlineIcon />
                            ) : (
                                <OfflineIcon />
                            )}
                        </span>
                    </>
                ) : (
                    <div className="refresh-network-header pe-3">
                        <span className="fw-bold">
                            {Constants.networkDisplayMessage.network}
                        </span>{' '}
                        <span
                            className={
                                networkStatus ===
                                Constants.networkDisplayMessage.online
                                    ? 'network-online'
                                    : 'network-offline'
                            }
                        >
                            {networkStatus}
                        </span>{' '}
                        <span className="network-icon">
                            {networkStatus ===
                            Constants.networkDisplayMessage.online ? (
                                <OnlineIcon />
                            ) : (
                                <OfflineIcon />
                            )}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NetworkDisplay;
