import { Form, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { usersApi } from '../../../../../../Redux/Apis/Users/usersApi';
import { getApiTmAccountsResponse } from '../../../../../../Redux/Apis/Accounts/accountsApi';
import UserGeneralRole from './UserGeneralRole';
import UserAccountRole from './UserAccountRole';
import { useElementOnScreen } from '../../../../../../commonHooks/useElementOnScreen';
import Constants from '../../../../../../utils/Constants';
import { ChangeEventHandler, LegacyRef, useEffect, useState } from 'react';
import UserModalHeader from './UserModalHeader';
import {
    PostApiTmUsersApiArg,
    PutApiTmUsersByIdApiArg,
    TenantManagementModelsUserManagementAccountUserModelRead,
    TenantManagementModelsUserManagementRoleModel,
    TenantManagementModelsUserManagementScopeModel,
    TenantManagementModelsUserManagementUserModelRead,
} from '../../../../../../Redux/Apis/Users/baseUsersApi';
import ProtectedSpinnerButton from '../../../../../../components/common/ProtectedSpinnerButton';
import AddRole from './AddRole';
import {
    IAdditionalRole,
    UserModelWithRequiredNames,
} from '../interfaces/UserManagementInterface';
import { deepClone } from '../../../../../../helpers';
import { additionalRoleInitialState } from '../constants/userManagementInitialState';
import { UserManagementConstants } from '../constants/UserManagementConstants';
import { validateAccordingType } from '../../../../../../utils/validation';
import { toast } from 'react-toastify';
import {
    Roles,
    RolesAndScopesByAppDropdownOptionForModal,
} from '../../../../../common/modules/auth/constants/roles';
import { AppScope } from '../../../../../common/modules/auth/constants/appScope';
import { AppDropdownOptionForModal } from '../../../../../common/modules/auth/constants/appDropdownOption';
import {
    useGetDataForFilterDropdowns,
    getUserManagementUsersQueryArgs,
} from '../hooks/userManagementHooks';
import { useAppSelector } from '../../../../../../Redux/Store';

const UserModal = ({
    show,
    onHide,
    userId,
    appDropdownOptionsForModal,
    accounts,
}: {
    show: boolean;
    onHide: () => void;
    userId: number;
    appDropdownOptionsForModal: AppDropdownOptionForModal[];
    accounts: getApiTmAccountsResponse[];
}) => {
    const { t } = useTranslation('userManagement');
    const userManagementFilter = useAppSelector(
        state => state.userManagement.userManagementFilter,
    );

    const [additionalRoles, setAdditionalRoles] = useState<IAdditionalRole[]>(
        [],
    );
    const [user, setUser] = useState<UserModelWithRequiredNames>();
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [createPasswordForUser, setCreatePasswordForUser] = useState(false);
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [containerRef] = useElementOnScreen({
        ...Constants.useElementOnScreen.options,
    });

    const [fetchSelectedUser] = usersApi.useLazyGetApiTmUsersOdataQuery();
    const [triggerUserUpdate] = usersApi.usePutApiTmUsersByIdMutation();
    const [triggerAddingUser] = usersApi.usePostApiTmUsersMutation();

    const isCreateUser = !userId;

    const calculateAccountRoles = (
        accounts: TenantManagementModelsUserManagementAccountUserModelRead[],
    ) => {
        additionalRoles
            .filter(additionalRole => additionalRole.accountId)
            .forEach(additionalRole => {
                const updatedAccount = accounts.find(
                    account => account.accountId === additionalRole.accountId,
                );
                if (updatedAccount) {
                    updatedAccount.roles = [
                        ...(updatedAccount.roles ?? []),
                        { name: additionalRole.role },
                    ];
                } else {
                    accounts.push({
                        accountId: additionalRole.accountId,
                        roles: [
                            {
                                name: additionalRole.role,
                            },
                        ],
                    });
                }
            });
        const updatedAccounts = accounts.filter(
            account => account.roles?.length,
        );
        return updatedAccounts;
    };

    const calculateScopes = (
        payload: TenantManagementModelsUserManagementUserModelRead,
    ): (
        | TenantManagementModelsUserManagementScopeModel
        | {
              name: AppScope;
          }
    )[] => {
        const newAppScopes: Set<{ name: AppScope }> = new Set();

        const allRolesForUser: Roles[] = [
            ...(payload?.roles?.map(role => role.name) ?? []),
            ...(payload?.accounts
                ?.map(account => account.roles?.map(role => role.name))
                .flat() ?? []),
            ...additionalRoles.map(role => role.role),
        ]
            .filter(roleName => roleName && roleName in Roles)
            .map(roleName => roleName as Roles);

        Object.entries(RolesAndScopesByAppDropdownOptionForModal).forEach(
            ([_, { roles: appRoles, appScopes }]) => {
                if (appRoles.some(role => allRolesForUser.includes(role))) {
                    appScopes.forEach(scopeToAdd => {
                        newAppScopes.add({ name: scopeToAdd });
                    });
                }
            },
        );

        const nonAppScopes = deepClone(payload.scopes ?? []).filter(
            scope => scope.name && !(scope.name in AppScope),
        );
        const updatedScopes = [...nonAppScopes, ...Array.from(newAppScopes)];

        return updatedScopes;
    };

    const calculateGeneralRoles = (
        roles: TenantManagementModelsUserManagementRoleModel[],
    ) => {
        const additionalGeneralRoles = additionalRoles.filter(
            role => role.app !== AppDropdownOptionForModal['Feedlot Manager'],
        );
        const allGeneralRoles: { name: string }[] = [
            ...roles.map(role => ({ name: role.name })),
            ...additionalGeneralRoles.map(role => ({
                name: role.role,
            })),
        ]
            .filter(role => typeof role.name === 'string')
            .map(role => role as { name: string });

        return allGeneralRoles;
    };

    const removeSpacesFromRoleNames = (
        updatedUser: TenantManagementModelsUserManagementUserModelRead,
    ): TenantManagementModelsUserManagementUserModelRead => {
        updatedUser.roles = updatedUser.roles?.map(role => ({
            ...role,
            name: role.name?.split(' ').join(''),
        }));
        updatedUser.accounts = updatedUser.accounts?.map(account => ({
            ...account,
            roles: account.roles?.map(role => ({
                ...role,
                name: role.name?.split(' ').join(''),
            })),
        }));
        return updatedUser;
    };

    const handleUserAddition = async (payload: PostApiTmUsersApiArg) => {
        try {
            await triggerAddingUser(payload).unwrap();
            refetchCurrentUsersList();
            toast.success(t('userModalLabels.successfullyAdded'));
            onHide();
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: { status: number } | any) {
            if (err?.status === 409) {
                toast.error(t('userModalLabels.failedAddDuplicateEmail'));
                setEmailIsInvalid(true);
            } else {
                toast.error(t('userModalLabels.failedAdd'));
            }
        }
    };

    const handleUserUpdate = async (payload: PutApiTmUsersByIdApiArg) => {
        try {
            await triggerUserUpdate(payload).unwrap();
            refetchCurrentUsersList();
            toast.success(t('userModalLabels.successfullyUpdated'));
            onHide();
        } catch {
            toast.error(t('userModalLabels.failedUpdate'));
        }
    };

    const [refetchUsers] = usersApi.useLazyGetApiTmUsersOdataInfScrollQuery();
    const refetchCurrentUsersList = () => {
        const filter = {
            ...userManagementFilter,
            offset: 0,
            limit: userManagementFilter.limit + userManagementFilter.offset,
        };

        refetchUsers(getUserManagementUsersQueryArgs(filter));
    };

    const handleSubmit = async () => {
        if (user) {
            setIsSubmitting(true);
            let updatedUser: TenantManagementModelsUserManagementUserModelRead =
                deepClone(user);

            updatedUser.roles = calculateGeneralRoles(updatedUser.roles ?? []);
            updatedUser.accounts = calculateAccountRoles(
                updatedUser.accounts ?? [],
            );
            updatedUser.scopes = calculateScopes(updatedUser);
            updatedUser = removeSpacesFromRoleNames(updatedUser);

            if (isCreateUser) {
                const payload: PostApiTmUsersApiArg = {
                    tenantManagementModelsUserManagementUserModel: updatedUser,
                };
                if (createPasswordForUser) {
                    if (validateAccordingType('password', password)) {
                        payload.tenantManagementModelsUserManagementUserModel.userpass =
                            password;
                        await handleUserAddition(payload);
                    } else {
                        setPasswordIsInvalid(true);
                        toast.error(t('userModalLabels.invalidPassword'));
                    }
                } else if (
                    validateAccordingType(
                        'email',
                        payload.tenantManagementModelsUserManagementUserModel
                            .username ?? '',
                    )
                ) {
                    payload.template = 'Invitation'; //invite user to create their own password
                    await handleUserAddition(payload);
                } else {
                    setEmailIsInvalid(true);
                    toast.error(t('userModalLabels.invalidEmail'));
                }
            } else if (updatedUser.userId) {
                const payload: PutApiTmUsersByIdApiArg = {
                    tenantManagementModelsUserManagementUserModel: updatedUser,
                    id: updatedUser.userId,
                };
                await handleUserUpdate(payload);
            }
            setIsSubmitting(false);
        }
    };

    const changeName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        if (user) {
            setUser({
                ...user,
                [target.name]: target.value,
            });
        }
    };

    const changeEmail: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        if (user) {
            setUser({
                ...user,
                username: target.value,
            });
            setEmailIsInvalid(false);
        }
    };

    const submitIsDisabled =
        !user?.firstName ||
        !user?.username ||
        additionalRoles.some(
            additionalRole =>
                additionalRole.app === 'Select an App' ||
                additionalRole.role === 'Select a Role' ||
                (additionalRole.app ===
                    AppDropdownOptionForModal['Feedlot Manager'] &&
                    !additionalRole.accountId),
        ) ||
        (createPasswordForUser && !password) ||
        (!createPasswordForUser && !user?.username) ||
        isSubmitting;

    const addRoleRow = () => {
        setAdditionalRoles([...additionalRoles, additionalRoleInitialState]);
    };

    const setPasswordCheckbox = () => {
        setPassword('');
        setCreatePasswordForUser(!createPasswordForUser);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isCreateUser) {
                setUser({
                    firstName: '',
                    lastName: '',
                    username: '',
                    enabled: true,
                });
            } else {
                const res = await fetchSelectedUser({
                    include: 'Roles,Scopes,Accounts.Roles',
                    filter: `UserId eq ${userId}`,
                    top: 1,
                });
                if (res.error) {
                    toast.error(t('userModalLabels.failedFetch'));
                } else if (res.data) {
                    const selectedUser: UserModelWithRequiredNames = {
                        ...res.data[0],
                        firstName: res.data[0].firstName ?? '',
                        lastName: res.data[0].lastName ?? '',
                        username: res.data[0].username ?? '',
                        enabled: res.data[0].enabled ?? false,
                    };
                    setUser(selectedUser);
                }
            }
        };
        fetchData();
    }, [fetchSelectedUser, isCreateUser, t, userId]);

    const { editingPrivileges } = useGetDataForFilterDropdowns();
    const isStatusToggleDisabled =
        !editingPrivileges.isAdmin && !editingPrivileges.isSuperAdmin;

    return (
        <>
            <Modal
                className="px-2 pt-5"
                data-size="md"
                show={show}
                centered
                animation
                onHide={onHide}
            >
                {user && (
                    <div className={'mx-3 my-3'}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-secondary">
                                <h5 className="modal-title">
                                    {isCreateUser
                                        ? t('userModalLabels.addUser')
                                        : t('userModalLabels.editUser')}
                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={'mb-3'}>
                                <div className="row mb-2">
                                    <div className="col">
                                        <Form.Label>
                                            <strong>
                                                {t('userModalLabels.firstName')}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            name="firstName"
                                            value={user.firstName}
                                            onChange={changeName}
                                            placeholder={t(
                                                'userModalLabels.enterFirstName',
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Form.Label>
                                            <strong>
                                                {t('userModalLabels.lastName')}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            name="lastName"
                                            value={user.lastName}
                                            onChange={changeName}
                                            autoComplete="off"
                                            placeholder={t(
                                                'userModalLabels.enterLastName',
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <Form.Label>
                                            <strong>
                                                {t('userModalLabels.email')}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            className={`${emailIsInvalid && 'border-danger'}`}
                                            name="email"
                                            value={user.username}
                                            onChange={changeEmail}
                                            autoComplete="off"
                                            disabled={!isCreateUser}
                                            placeholder={t(
                                                'userModalLabels.enterEmail',
                                            )}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="col-xl-5 col-lg-5 start-top mb-2"
                                    hidden={isCreateUser}
                                >
                                    {UserManagementConstants.statuses.map(
                                        (status, index) => (
                                            <div
                                                className="d-flex align-items-center mb-1"
                                                key={index}
                                            >
                                                <input
                                                    type="radio"
                                                    className={`${isStatusToggleDisabled ? 'cursor-default' : ''}`}
                                                    id={status.enabled.toString()}
                                                    checked={
                                                        user.enabled ===
                                                        status.enabled
                                                    }
                                                    onChange={() => {
                                                        setUser({
                                                            ...user,
                                                            enabled:
                                                                status.enabled,
                                                        });
                                                    }}
                                                    disabled={
                                                        isStatusToggleDisabled
                                                    }
                                                />
                                                <label
                                                    className="form-check-label ms-2"
                                                    htmlFor={status.enabled.toString()}
                                                >
                                                    {status.label}
                                                </label>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                            <div
                                className={`rounded edit-user-modal-table table-responsive d-md-block d-none table-scroll-div`}
                            >
                                <Table
                                    borderless
                                    striped
                                    hover
                                    className="mb-0"
                                >
                                    <UserModalHeader />
                                    <tbody>
                                        {user.roles?.map((role, index) => (
                                            <UserGeneralRole
                                                key={index}
                                                role={role}
                                                setUser={setUser}
                                                index={index}
                                            />
                                        ))}
                                        {user.accounts?.map(
                                            (account, accountIndex) => {
                                                if (
                                                    accounts.some(
                                                        availableAccount =>
                                                            availableAccount.accountId ===
                                                            account.accountId,
                                                    )
                                                ) {
                                                    return account?.roles?.map(
                                                        (role, roleIndex) => (
                                                            <UserAccountRole
                                                                key={`${account.accountId}-${roleIndex}`}
                                                                role={role}
                                                                account={
                                                                    account
                                                                        ?.account
                                                                        ?.name ??
                                                                    ''
                                                                }
                                                                setUser={
                                                                    setUser
                                                                }
                                                                accountIndex={
                                                                    accountIndex
                                                                }
                                                                roleIndex={
                                                                    roleIndex
                                                                }
                                                            />
                                                        ),
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            },
                                        )}
                                        <AddRole
                                            appsForDropdown={
                                                appDropdownOptionsForModal
                                            }
                                            accounts={accounts}
                                            additionalRoles={additionalRoles}
                                            setAdditionalRoles={
                                                setAdditionalRoles
                                            }
                                            user={user}
                                        />
                                        <tr
                                            ref={
                                                containerRef as LegacyRef<HTMLTableRowElement>
                                            }
                                        />
                                    </tbody>
                                </Table>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="add-row-btn me-3 mt-2"
                                    onClick={() => addRoleRow()}
                                >
                                    <i className="far fa-plus"></i>
                                    {t('userModalLabels.addRole')}
                                </button>
                            </div>
                            {isCreateUser && (
                                <>
                                    <div className="d-flex">
                                        <input
                                            className="cursor-pointer me-2"
                                            type="radio"
                                            checked={!createPasswordForUser}
                                            onChange={setPasswordCheckbox}
                                        />
                                        <label className="mt-1">
                                            {t('userModalLabels.inviteUser')}
                                        </label>
                                    </div>
                                    <div className="d-flex">
                                        <input
                                            className="cursor-pointer me-2"
                                            type="radio"
                                            checked={createPasswordForUser}
                                            onChange={setPasswordCheckbox}
                                        />
                                        <label className="mt-1">
                                            {t(
                                                'userModalLabels.createPasswordForUser',
                                            )}
                                        </label>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <Form.Label>
                                            <strong>
                                                {t('userModalLabels.password')}
                                            </strong>
                                        </Form.Label>
                                        <div className="d-flex">
                                            <Form.Control
                                                type={'text'}
                                                className={`pe-0 ${passwordIsInvalid && 'border-danger'}`}
                                                name="password"
                                                value={password}
                                                onChange={e =>
                                                    setPassword(e.target.value)
                                                }
                                                disabled={
                                                    !createPasswordForUser
                                                }
                                                placeholder={t(
                                                    'userModalLabels.enterPassword',
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {t('userModalLabels.passwordRules')}
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <ProtectedSpinnerButton
                                disabled={isSubmitting}
                                showSpinner={false}
                                buttonContent={t('userModalLabels.cancel')}
                                className="secondary"
                                onClick={onHide}
                            />
                            <ProtectedSpinnerButton
                                disabled={submitIsDisabled}
                                showSpinner={isSubmitting}
                                buttonContent={t('userModalLabels.save')}
                                onClick={handleSubmit}
                            />
                        </Modal.Footer>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default UserModal;
