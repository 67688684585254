import { useNavigate } from 'react-router-dom';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { financeManagerFeatures } from '../../apps/financeManager/featureFlags/financeManagerFeatures';

const SidebarLogo = (props: any) => {
    const usersRoles = useAuthContext().getUsersRoles();
    const { isNewIconImplement } = financeManagerFeatures;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const handleClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            dispatch(setRoute('/'));
        } else {
            navigate('/');
        }
    };

    return (
        <>
            <img
                {...props}
                alt="./sidebar_financial.png"
                src={
                    isNewIconImplement
                        ? usersRoles.includes('WarehousingApp')
                            ? require('./sidebar_financial_warehousing.png')
                            : require('./sidebar_financial_new.png')
                        : require('./sidebar_financial.png')
                }
                title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
                onClick={handleClick}
            />
        </>
    );
};

export default SidebarLogo;
