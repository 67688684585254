import { Button, Form, Modal } from 'react-bootstrap';
import {
    IOwnerDetail,
    IOwnerDetailsError,
    IOwnerModal,
} from '../../utils/Interface/OwnerInterface';
import { ownerConstants } from '../../utils/constants/OwnerConstants';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { useEffect, useState } from 'react';
import { useLoading } from '../../commonHooks/useLoading';
import FullPageLoader from '../common/FullPageLoader';
import {
    createOwner,
    fetchInactiveOwnerInfo,
    updateOwnerDetail,
} from '../../Redux/Actions/ownerActions';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { OWNER_INITIAL_STATE } from '../../Redux/Constants/ownerInitialState';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13076On_OwnerActiveCheckboxFix } = feedlotManagerFeatures;

const OwnerModal = ({
    showModal,
    closeModalWithChanges,
    editOwnerDetails,
    showActiveField,
}: IOwnerModal) => {
    const [ownerDetail, setOwnerDetail] =
        useState<IOwnerDetail>(editOwnerDetails);
    const [ownerDetailsError, setOwnerDetailsError] =
        useState<IOwnerDetailsError>(ownerConstants.initialOwnerDetailsError);
    const [inActiveOwner, setInActiveOwner] = useState<IOwnerDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const handleActivateOwner = async () => {
        if (feedlotId && ownerDetail.name) {
            const inactiveOwnerInfo = await dispatch(
                fetchInactiveOwnerInfo(ownerDetail.name.trim()),
            );
            if (inactiveOwnerInfo) {
                setInActiveOwner(inactiveOwnerInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateOwnerDetail = (): void => {
        const isValid = validateOwnerDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateOwnerDetail(ownerDetail, {
                    closeModalWithChanges,
                    setLoading,
                    handleActivateOwner,
                }),
            );
        }
    };

    const validateForm = (ownerErrorCopy: IOwnerDetailsError) => {
        let isFormValid = true;
        const validateFields = ['contactNumber'];
        const validateTypes = ['mobileLength'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                ownerDetail[validateField],
            );
            if (!ownerErrorCopy[validateField] && !isValid) {
                ownerErrorCopy[validateField] = true;
            }

            if (!isValid) {
                isFormValid = false;
            }
        });

        setOwnerDetailsError(ownerErrorCopy);

        return isFormValid;
    };

    const validateOwnerDetails = (): boolean => {
        let isValid = true;
        const {
            name,
            contactNumber,
            email,
            address1,
            city,
            state,
            postalCode,
        } = ownerDetail;
        const updatedOwnerDetailErrors = {
            name: !name,
            contactNumber: !contactNumber,
            email: !email,
            address1: !address1,
            city: !city,
            state: !state,
            postalCode: !postalCode,
        };
        setOwnerDetailsError(updatedOwnerDetailErrors);
        const isFormValid = validateForm(updatedOwnerDetailErrors);
        if (
            Object.values(updatedOwnerDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }

        return isValid;
    };

    const handleCreateOwner = (): void => {
        const isValid = validateOwnerDetails();
        if (feedlotId && isValid) {
            dispatch(
                createOwner(
                    ownerDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateOwner,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setOwnerDetail(prevOwnerDetail => ({
                ...prevOwnerDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveOwner) {
                const inActiveOwnerCopy = {
                    ...inActiveOwner,
                    active: true,
                };
                dispatch(
                    updateOwnerDetail(inActiveOwnerCopy, {
                        closeModalWithChanges,
                        setLoading,
                    }),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setOwnerDetail({ ...OWNER_INITIAL_STATE.createOwnerInitial });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={ownerDetail.name?.trim()}
                item={ownerConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editOwnerDetails.name
                            ? `${ownerConstants.editOwner} ${editOwnerDetails?.name}`
                            : ownerConstants.createOwners}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form.name
                                            .label
                                    }
                                    <span className="necessaryFields">*</span>
                                </Form.Label>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.name ? 'danger' : ''
                                    }`}
                                    value={ownerDetail?.name}
                                    name={
                                        ownerConstants.createNewOwners.form.name
                                            .name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form.name
                                            .placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    maxLength={
                                        ownerConstants.createNewOwners.form.name
                                            .maxLength
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            {showActiveField && (
                                <div className="custom-checkbox col-5 ">
                                    <input
                                        type="checkbox"
                                        checked={
                                            isCA13076On_OwnerActiveCheckboxFix
                                                ? ownerDetail.isActive
                                                : ownerDetail.active
                                        }
                                        className="mx-2"
                                        onChange={e =>
                                            handleDetailChange(
                                                isCA13076On_OwnerActiveCheckboxFix
                                                    ? !ownerDetail.isActive
                                                    : !ownerDetail.active,
                                                isCA13076On_OwnerActiveCheckboxFix
                                                    ? 'isActive'
                                                    : 'active',
                                            )
                                        }
                                    />
                                    <label className="mx-2">
                                        {FmSettingsConstant.active.label}
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form
                                            .email.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.email ? 'danger' : ''
                                    }`}
                                    value={ownerDetail?.email}
                                    name={
                                        ownerConstants.createNewOwners.form
                                            .email.name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form
                                            .email.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form
                                            .contactNumber.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.contactNumber
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={ownerDetail?.contactNumber}
                                    name={
                                        ownerConstants.createNewOwners.form
                                            .contactNumber.name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form
                                            .contactNumber.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form
                                            .address.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.address1
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={ownerDetail?.address1}
                                    name={
                                        ownerConstants.createNewOwners.form
                                            .address.name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form
                                            .address.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form.city
                                            .label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.city ? 'danger' : ''
                                    }`}
                                    value={ownerDetail?.city}
                                    name={
                                        ownerConstants.createNewOwners.form.city
                                            .name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form.city
                                            .placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form
                                            .state.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.state ? 'danger' : ''
                                    }`}
                                    value={ownerDetail?.state}
                                    name={
                                        ownerConstants.createNewOwners.form
                                            .state.name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form
                                            .state.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        ownerConstants.createNewOwners.form
                                            .zipcode.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        ownerDetailsError.postalCode
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={ownerDetail?.postalCode}
                                    name={
                                        ownerConstants.createNewOwners.form
                                            .zipcode.name
                                    }
                                    placeholder={
                                        ownerConstants.createNewOwners.form
                                            .zipcode.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editOwnerDetails.name
                                ? handleCreateOwner
                                : handleUpdateOwnerDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OwnerModal;
