import { deepClone } from '../../../../helpers';
import { CommodityUnit } from '../interfaces/ManageCommoditiesInterface';
import Big from 'big.js';

interface IBaseCommodityItem {
    unit?: CommodityUnit;
}

export const convertCommodityItem = <
    T extends IBaseCommodityItem,
    K extends keyof T & string,
>(
    commodityItem: T,
    fieldsToConvert: K[],
    currentWeightUnit: CommodityUnit,
): T => {
    const commodityItemCopy = deepClone(commodityItem);

    if (!commodityItemCopy.unit) {
        commodityItemCopy.unit = CommodityUnit.Lbs;
    }

    if (commodityItemCopy.unit !== currentWeightUnit) {
        fieldsToConvert.forEach(field => {
            const fieldValue = commodityItemCopy[field];
            if (!+fieldValue) return;
            if (
                typeof fieldValue !== 'string' &&
                typeof fieldValue !== 'number'
            )
                return;
            const normalizedFieldValue = Big(fieldValue);
            const factor = Big(
                currentWeightUnit === CommodityUnit.Tons ? 2000 : 1 / 2000,
            );

            const convertedValue =
                field === 'price'
                    ? normalizedFieldValue.times(factor)
                    : normalizedFieldValue.div(factor);

            commodityItemCopy[field] = +convertedValue as T[K];
        });

        commodityItemCopy.unit = currentWeightUnit;
    }
    return commodityItemCopy;
};

export const convertCommoditiesItem = <
    T extends IBaseCommodityItem,
    K extends keyof T & string,
>(
    commoditiesItem: T[],
    fieldsToConvert: K[],
    currentWeightUnit: CommodityUnit,
): T[] => {
    return commoditiesItem.map((item: T) =>
        convertCommodityItem(item, fieldsToConvert, currentWeightUnit),
    );
};
