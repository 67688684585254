import { useNavigate } from 'react-router-dom';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13054On_NgatFmNewLogoIcon } = feedlotManagerFeatures;

const SidebarLogoFM = (props: any) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/`);
    return (
        <img
            {...props}
            alt="sidebar_fm.png"
            src={require(
                `${isCA13054On_NgatFmNewLogoIcon ? './sidebar_fm_new.png' : './sidebar_fm.png'}`,
            )}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogoFM;
