import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13076On_OwnerActiveCheckboxFix } = feedlotManagerFeatures;
export const ownerConstants = {
    createNewOwners: {
        heading: 'Create New Owner',
        form: {
            name: {
                name: 'name',
                label: 'Owner Name',
                maxLength: 50,
                placeholder: 'Enter Owner Name',
            },
            email: {
                name: 'email',
                label: 'Email Address',
                maxLength: 50,
                placeholder: 'Enter Email address',
            },
            contactNumber: {
                name: 'contactNumber',
                label: 'Phone Number',
                maxLength: 50,
                placeholder: 'Enter Phone Number',
            },
            address: {
                name: 'address1',
                label: 'Address',
                maxLength: 50,
                placeholder: 'Enter Address',
            },
            state: {
                name: 'state',
                label: 'State',
                maxLength: 50,
                placeholder: 'Enter State',
            },
            zipcode: {
                name: 'postalCode',
                label: 'Zip Code',
                maxLength: 50,
                placeholder: 'Enter Zipcode',
            },
            city: {
                name: 'city',
                label: 'City',
                maxLength: 50,
                placeholder: 'Enter City',
            },
            cancel: 'Cancel',
            saveOwners: 'Save Owners',
        },
    },
    ownerDetails: {
        ownerPlaceholder: 'Search Owner',
        lotPlaceholder: 'Select Lot',
        maxLength: 50,
        owner: 'Owner',
        lot: 'Lot',
        contactNumber: 'Phone Number',
        heading: 'Manage Owners',
        name: 'owner',
    },
    ownerColumnDetail: [
        { label: 'Owner', key: 'name' },
        {
            label: 'Status',
            key: isCA13076On_OwnerActiveCheckboxFix ? 'isActive' : 'active',
        },
        { label: 'Phone Number', key: 'contactNumber' },
        { label: 'Email Address', key: 'email' },
        { label: 'Head Count', key: 'totalHdCount' },
    ],
    initialOwnerDetailsError: {
        name: false,
        contactNumber: false,
        email: false,
        address1: false,
        city: false,
        state: false,
        postalCode: false,
    },
    activateModalConstant: {
        heading: 'Activate Owner',
        text: ' is currently Inactive. Activate and use this Owner.',
    },
    createOwners: 'Create New Owner',
    clearFilter: 'Clear Filter',
    editOwner: 'Edit Owner - ',
    ownerFilter: 'ownerFilter',
};
