import { useTranslation } from 'react-i18next';

const UserModalHeader = () => {
    const { t } = useTranslation('userManagement');

    const headers = t('userModalLabels.headers', {
        returnObjects: true,
    });

    return (
        <thead className="shadow-none">
            <tr className="row flex-row d-flex mx-0 no-hover-effect">
                {headers.map((header, index) => (
                    <th key={index} className="col-md-3">
                        {header.label}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default UserModalHeader;
