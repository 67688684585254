import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA10320On_EditDeletePenSettings } = feedlotManagerFeatures;

export const penConstants = {
    createNewPens: {
        heading: 'Create New Pens',
        form: {
            name: {
                name: 'name',
                label: 'Pen',
                maxLength: 50,
                placeholder: 'Enter Pen Name',
            },
            capacity: {
                name: 'headCapacity',
                label: 'Capacity',
                maxLength: 4,
                placeholder: 'Enter Capacity',
            },
            penType: {
                name: 'name',
                label: 'Pen Type',
                maxLength: 50,
                placeholder: 'Select Pen Type',
                options: [
                    { id: 0, name: 'Home' },
                    { id: 1, name: 'Hospital' },
                    { id: 2, name: 'Receiving' },
                    { id: 3, name: 'Buller' },
                    { id: 4, name: 'Railer' },
                ],
            },
            cancel: 'Cancel',
            savePens: 'Save Pens',
        },
    },
    penDetails: {
        placeholder: 'Search',
        maxLength: 50,
        pen: 'Pen',
        heading: 'Manage Pens',
        type: 'Type',
        capacity: 'Capacity',
    },
    penColumnDetail: [
        { label: 'Pen', key: 'name' },
        ...(isCA10320On_EditDeletePenSettings
            ? [{ label: 'Status', key: 'active' }]
            : []),
        { label: 'Type', key: 'kind' },
        { label: 'Capacity', key: 'headCapacity' },
    ],
    initialPenDetailsError: [
        {
            name: false,
            kind: false,
        },
    ],
    activateModalConstant: {
        heading: 'Activate Pen',
        text: ' is currently Inactive. Activate and use this Pen.',
    },
    deactivatePenInfo: 'Non-empty Pen cannot be deactivated',
    createPens: 'Create New Pens',
    clearFilter: 'Clear Filter',
    editPen: 'Edit Pen - ',
    activeKey: 'active',
    isActiveKey: 'isActive',
    active: 'Active',
    inActive: 'Inactive',
    penFilter: 'penFilter',
};
