import { AppRoles } from '../../auth/constants/AuthConstants';
import { IProduct } from '../interfaces/ProductCardInterface';
import cattleFinanceManagerLogo from '../assets/subscriptionIcons/Cattle_Finance_Manager_Logo_on_Blue.svg';
import cattlePOLogo from '../assets/subscriptionIcons/Cattle_PO_Logo_on_Blue.svg';
import feedlotManagerLogo from '../assets/subscriptionIcons/Feedlot_Manager_Logo_on_Blue.svg';

export const ProductConstants = {
    button: {
        subscribe: 'Subscribe',
        comingSoon: 'Coming Soon!',
        manage: 'Manage',
    },
    ProductPage: {
        Title: 'NextGen AgTech Products',
        BackButton: 'Back',
        CurrentSubscriptionsTitle: 'My Current Subscriptions',
        NoCustomerIdNotice:
            'Subscriptions are not enabled for this account. Please contact support at support@nextgenagtech.com.',
        ManageButton: 'Manage Subscriptions',
    },
    hostedPage: {
        id: 'id',
        state: 'state',
        completedStates: ['succeeded', 'acknowledged'],
    },
    labels: {
        selectAPlan: 'Select a Plan',
        products: 'Products',
        plan: 'Plan',
    },
    subsciptionCheckout: {
        productFamily: 'pfid',
        productPlan: 'ppid',
    },
};

const cattleFinanceManager: IProduct = {
    productFamily: 'cattleFinanceManager',
    productFamilyFromUrl: 'cattle-finance-manager',
    label: 'Cattle Finance Manager',
    description:
        'Manage your financed cattle to track hedges, interest rates, sales, and monthly statements. Let us do the paperwork.',
    appRole: AppRoles.CattleFinanceManager,
    plans: [
        {
            label: 'Monthly - $100/mo',
            cbSubscriptionItemPriceId: 'Cattle-Finance-Manager-USD-Monthly',
        },
    ],
    subscriptionDuration: 'Monthly Subscription',
    imgSrc: cattleFinanceManagerLogo,
    isSubscribable: true,
    shouldShowProduct: true,
};

const shippingTracker: IProduct = {
    productFamily: 'shippingTracker',
    productFamilyFromUrl: 'cattle-po',
    label: 'Cattle PO',
    description:
        'From the saleyard to the feedlot, track cattle from one convenient app. Shipping cattle just got easier.',
    appRole: AppRoles.ShippingTracker,
    plans: [
        {
            label: 'Monthly - $100/mo',
            cbSubscriptionItemPriceId: 'Shipping-Tracker-USD-Monthly',
        },
    ],
    subscriptionDuration: 'Monthly Subscription',
    imgSrc: cattlePOLogo,
    isSubscribable: true,
    shouldShowProduct: true,
};

const feedlotManager: IProduct = {
    productFamily: 'feedlotManager',
    productFamilyFromUrl: 'feedlot-manager',
    label: 'Feedlot Manager',
    description:
        'Track cattle received and shipped, feed, health, and more. In short: manage your feedlot better.',
    appRole: AppRoles.FeedlotManager,
    plans: [
        {
            label: 'Feedlot Manager - Head Count',
            cbSubscriptionItemPriceId: 'Feedlot-Manager-Head-Count-USD-Monthly',
        },
        {
            label: 'Feedlot Manager - Head Days',
            cbSubscriptionItemPriceId: 'Feedlot-Manager-Head-Days-USD-Monthly',
        },
    ],
    subscriptionDuration: 'Monthly Subscription',
    imgSrc: feedlotManagerLogo,
    isSubscribable: true,
    shouldShowProduct: true,
    types: ['Standard'], // , 'Calf Ranch' coming soon
};

export const NGATProductFamilies = {
    cattleFinanceManager,
    shippingTracker,
    feedlotManager,
};
