import { useNavigate } from 'react-router-dom';
import { financeManagerFeatures } from '../../apps/financeManager/featureFlags/financeManagerFeatures';

const SidebarLogoBuyPo = (props: any) => {
    const { isNewIconImplement } = financeManagerFeatures;
    const navigate = useNavigate();
    const handleClick = () => navigate(`/`);

    return (
        <img
            {...props}
            alt="./sidebar_buypo.png"
            src={require(
                isNewIconImplement
                    ? './sidebar_cattlepo.png'
                    : './sidebar_buypo.png',
            )}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogoBuyPo;
