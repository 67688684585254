import { ChangeEventHandler, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { passwordValidation } from '../../../../../../utils/validation';
import { IUser } from '../interfaces/UserManagementInterface';
import { usersApi } from '../../../../../../Redux/Apis/Users/usersApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const UserManagementResetPasswordModal = ({
    showModal,
    hideModal,
    user,
}: {
    showModal: boolean;
    hideModal: () => void;
    user: IUser;
}) => {
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false);
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordInputChange: ChangeEventHandler<
        HTMLInputElement
    > = event => {
        newPasswordError && setNewPasswordError(false);
        const { value } = event.currentTarget;
        setNewPassword(value);
    };

    const [changePassword] = usersApi.usePutApiTmUsersByIdPasswordMutation();

    const onSubmit = async () => {
        if (validateForm()) {
            const response = await changePassword({
                id: user.userId,
                tenantManagementModelsUserManagementRequestChangePasswordModel:
                    {
                        new: newPassword,
                        previous: undefined,
                    },
            });
            if (response.error && 'status' in response.error) {
                toast.error(t('userManagement:setPasswordModal.error'));
            } else {
                toast.success(t('userManagement:setPasswordModal.success'));
            }
            hideModal();
        } else {
            setNewPasswordError(true);
        }
    };

    const validateForm = () => {
        return passwordValidation(newPassword);
    };

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={showModal}
            centered
            animation
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">{`${t('userManagement:setPasswordModal.heading')} ${user.name}`}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mx-0 mb-lg-2">
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t(
                                'userManagement:setPasswordModal.labels.newPassword',
                            )}
                        </Form.Label>
                        <div className="d-flex flex-row">
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                value={newPassword}
                                onChange={handlePasswordInputChange}
                                isInvalid={newPasswordError}
                                autoComplete="new-password"
                            />
                            <Button
                                className="secondary"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i
                                    className={
                                        showPassword
                                            ? 'fas fa-eye'
                                            : 'fas fa-eye-slash'
                                    }
                                ></i>
                            </Button>
                        </div>
                        <div className="px-2 pt-1 text-muted">
                            {t(
                                'userManagement:setPasswordModal.passwordRequirements',
                            )}
                        </div>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="secondary mx-2" onClick={hideModal}>
                    {t('userManagement:setPasswordModal.buttons.cancel')}
                </Button>
                <Button className="mx-2" onClick={onSubmit}>
                    {t('userManagement:setPasswordModal.buttons.submit')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserManagementResetPasswordModal;
