import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { FEED_STATUS_INITIAL_STATE } from '../Constants/feedStatusInitialState';

const FeedStatusReducer = (
    state = FEED_STATUS_INITIAL_STATE,
    action: AnyAction,
) => {
    const stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.SET_FEED_STATUS_LOADING:
            stateCopy.loading = action.payload;
            return stateCopy;
        case ActionTypes.SET_LOAD_INFORMATION_DETAIL:
            stateCopy.loadInformation = action.payload;
            return stateCopy;
        case ActionTypes.SET_PEN_INFORMATION_DETAIL:
            stateCopy.penInformation = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_STATUS_CAN_CALL_API:
            stateCopy.canCallApi = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_STATUS_FILTER:
            stateCopy.filter = action.payload;
            return stateCopy;
        case ActionTypes.SET_RATIONS:
            stateCopy.rations = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEEDINGS:
            stateCopy.feedings = action.payload;
            return stateCopy;
        case ActionTypes.SET_DRIVERS:
            stateCopy.drivers = action.payload;
            return stateCopy;
        case ActionTypes.SET_PENS:
            stateCopy.pens = action.payload;
            return stateCopy;
        case ActionTypes.SET_SELECTED_FEED_TASK_ID:
            stateCopy.selectedFeedTaskId = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_STATUS_REFRESH_TIME:
            stateCopy.refreshTime = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_STATUS_LOADED_COMMODITIES:
            stateCopy.loadedCommodities = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
};

export default FeedStatusReducer;
