import { Col, Spinner } from 'react-bootstrap';
import { CommonConstants } from '../../../utils/constants/CommonConstants';
import { penConstants } from '../../../utils/constants/PenDetailsConstant';
import { mixingBinConstants } from '../../../utils/constants/MixingBinConstants';
import { truckConstants } from '../../../utils/constants/TruckConstant';
import {
    BaseDetail,
    IColumnDetail,
    ISettingsItemDetail,
} from '../../../utils/Interface/PenInterface';
import EditIcon from '../../../Assets/EditIcon';
import DeleteIcon from '../../../Assets/DeleteIcon';
import { yardageConstants } from '../../../utils/constants/YardageConstants';
import { formatDate } from '../../../helpers';
import { feedlotManagerFeatures } from '../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA10320On_EditDeletePenSettings } = feedlotManagerFeatures;

const SettingsItemDetail = <T extends BaseDetail>({
    columnDetail,
    itemDetail,
    handleEditDetail,
    handleDeleteModalHandler,
    loader,
    columnSize = 3,
    margin = 4,
}: ISettingsItemDetail<T>) => {
    const getColumnContent = (item: T, column: IColumnDetail) => {
        let resolvedKey = column.key;

        const keysWithCommas = [
            mixingBinConstants.mixingBinColumnDetail[2].key,
            truckConstants.truckColumnDetail[3].key,
            isCA10320On_EditDeletePenSettings
                ? penConstants.penColumnDetail[3].key
                : penConstants.penColumnDetail[2].key,
            yardageConstants.yardageColumnDetail[0].key,
        ];

        if (keysWithCommas.includes(column.key)) {
            resolvedKey = 'commaFormatted';
        }

        switch (resolvedKey) {
            case penConstants.isActiveKey:
            case penConstants.activeKey:
                return item[column.key]
                    ? penConstants.active
                    : penConstants.inActive;
            case yardageConstants.yardageRate:
                return `$${item[column.key].toFixed(2)}`;
            case yardageConstants.effectiveDate:
                return formatDate(item[column.key]);
            case 'commaFormatted':
                return item[column.key]?.toLocaleString();
            default:
                return item[column.key];
        }
    };

    return (
        <>
            <Col className="d-flex align-items-center p-2 mt-1">
                {columnDetail.map(column => (
                    <Col
                        xs={
                            column.key === 'email' && columnSize === 1
                                ? '2'
                                : columnSize
                        }
                        className={`mx-${margin}`}
                        key={column.key}
                    >
                        <h6>{column.label}</h6>
                    </Col>
                ))}
            </Col>
            <div
                className={`${columnDetail[0].label == 'Yardage' ? 'yardage-item-detail-container-height' : 'settings-item-detail-container-height'}  settings-item-detail-container m-2`}
            >
                {loader && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner
                            animation="border"
                            data-size="lg"
                            className="text-center"
                        />
                    </div>
                )}
                {itemDetail.length === 0 && !loader && (
                    <Col>{CommonConstants.en.noDataFound}</Col>
                )}
                {itemDetail.map(item => (
                    <Col
                        className="d-flex align-items-center p-2 item-row settings-item-detail"
                        key={item.name}
                    >
                        {columnDetail.map(column => (
                            <Col
                                className={`mx-${margin} text-break text-wrap`}
                                xs={columnSize}
                                key={column.key}
                            >
                                {getColumnContent(item, column)}
                            </Col>
                        ))}
                        <Col className="d-flex justify-content-end">
                            {handleEditDetail && (
                                <EditIcon
                                    className="cursor-pointer custom-icon mx-2"
                                    onClick={() => handleEditDetail(item)}
                                />
                            )}
                            {handleDeleteModalHandler && (
                                <DeleteIcon
                                    className="cursor-pointer custom-icon"
                                    onClick={() =>
                                        handleDeleteModalHandler(item)
                                    }
                                ></DeleteIcon>
                            )}
                        </Col>
                    </Col>
                ))}
            </div>
        </>
    );
};

export default SettingsItemDetail;
