import { useAppSelector } from '../../../../../../Redux/Store';
import {
    AccountRole,
    IUser,
    IUserManagementFilter,
    UserStatusEnum,
} from '../interfaces/UserManagementInterface';
import { usersApi } from '../../../../../../Redux/Apis/Users/usersApi';
import {
    accountsApi,
    getApiTmAccountsResponse,
} from '../../../../../../Redux/Apis/Accounts/accountsApi';
import { TenantManagementModelsUserManagementUserModelRead } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import useAuthContext from '../../../../../common/modules/auth/hooks/useAuthContext';
import { deepClone } from '../../../../../../helpers';
import {
    Roles,
    RolesAndScopesByAppDropdownOptionForModal,
    RolesByAppDropdownOptionForFilter,
} from '../../../../../common/modules/auth/constants/roles';
import {
    AppDropdownToAppScopeForDatabase,
    AppScope,
    AppScopeToAppDropdownOptionForFilter,
} from '../../../../../common/modules/auth/constants/appScope';
import {
    AppDropdownOptionForModal,
    AppDropdownOptionForFilter,
} from '../../../../../common/modules/auth/constants/appDropdownOption';
import { skipToken } from '@reduxjs/toolkit/query';
import i18n from 'i18next';

export const useGetUserManagementUsersQueryArgs = () => {
    const userManagementFilter = useAppSelector(
        state => state.userManagement.userManagementFilter,
    );
    return getUserManagementUsersQueryArgs(userManagementFilter);
};

export const getUserManagementUsersQueryArgs = (
    userManagementFilter: IUserManagementFilter,
) => {
    const getUserQueryString = () => {
        const filterConditions = [
            ...(userManagementFilter.name
                ? [
                      `contains(concat(concat(FirstName, ' '), LastName), '${userManagementFilter.name}')`,
                  ]
                : []),
            ...(userManagementFilter.email
                ? [`contains(Username, '${userManagementFilter.email}')`]
                : []),
            ...(+userManagementFilter.accountId
                ? [
                      `(Accounts/any(account:account/AccountId eq ${userManagementFilter.accountId}) or Accounts/any() eq false)`,
                  ]
                : []),
            ...(userManagementFilter.app &&
            userManagementFilter.app !==
                i18n.t(
                    'userManagement:userManagementLabels.staticOptions.selectApp',
                )
                ? [
                      `Scopes/any(scope:scope/name eq '${AppDropdownToAppScopeForDatabase[userManagementFilter.app]}')`,
                  ]
                : []),
            ...(userManagementFilter.status === UserStatusEnum.Active
                ? [`Enabled eq true`]
                : userManagementFilter.status === UserStatusEnum.Inactive
                  ? [`Enabled eq false`]
                  : []),
        ];

        const filterClause = filterConditions.filter(Boolean).join(' and ');
        return filterClause || undefined;
    };

    const roleQuery =
        userManagementFilter.role &&
        userManagementFilter.role !==
            i18n.t(
                'userManagement:userManagementLabels.staticOptions.selectRole',
            )
            ? `${userManagementFilter.role.replace(/\s/g, '')}`
            : undefined;

    const queryArgs = {
        include: 'Roles,Scopes,Accounts.Roles',
        filter: getUserQueryString(),
        orderBy: 'FirstName,LastName',
        top: userManagementFilter.limit,
        skip: userManagementFilter.offset,
        role: roleQuery,
    };
    return queryArgs;
};

const filterApps = apps => {
    return apps.filter(app => app.name.startsWith('APP:'));
};

export const spaceRoleName = (appName: string) => {
    const result = appName.replace(/(?<!^)([A-Z])/g, ' $1');
    return result;
};

const getGeneralRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles: Roles[],
) => {
    return (
        user.roles
            ?.filter(role =>
                validAppRoles.some(
                    appRole => role.name && appRole === role.name,
                ),
            )
            ?.map(role => role.name ?? '') ?? []
    );
};

const getAccountRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles: Roles[],
    accessibleAccounts: getApiTmAccountsResponse[],
    selectedAccountId: number,
    useUnfilteredAccounts: boolean,
): AccountRole[] => {
    const accountsAndRoles: AccountRole[] = [];

    const filteredAccounts = user.accounts?.filter(account =>
        selectedAccountId
            ? account.accountId === selectedAccountId
            : accessibleAccounts.some(
                  accessibleAccount =>
                      accessibleAccount.accountId === account.accountId,
              ),
    );
    const accountsToAdd = useUnfilteredAccounts
        ? user.accounts
        : filteredAccounts;

    accountsToAdd?.forEach(account =>
        account?.roles
            ?.filter(role =>
                validAppRoles.some(
                    appRole => role.name && appRole === role.name,
                ),
            )
            ?.forEach(role => {
                accountsAndRoles.push({
                    account: account.account?.name,
                    role: role.name,
                });
            }),
    );
    return accountsAndRoles;
};

const handleAdminRoles = (
    generalRoles: string[],
    isAdmin: boolean,
    canAffectOtherAdmin: boolean,
) => {
    if (generalRoles.includes('SuperAdmin')) {
        return false;
    } else if (
        isAdmin &&
        (canAffectOtherAdmin || !generalRoles.includes('Admin'))
    ) {
        return true;
    }
};

const handleFeedlotManager = (
    accountsAndRoles: AccountRole[],
    myAccountRoles: (string | null | undefined)[],
    generalRoles: string[],
    requireFeedlotAffiliation: boolean,
) => {
    if (generalRoles.some(role => role !== 'User')) {
        return false;
    }

    let hasPermission = false;

    if (accountsAndRoles.length) {
        requireFeedlotAffiliation
            ? myAccountRoles.forEach(myRole => {
                  if (
                      accountsAndRoles.some(
                          accountRole => myRole === accountRole.account,
                      ) &&
                      !accountsAndRoles.some(
                          accountRole => accountRole.role === 'FeedlotManager',
                      )
                  ) {
                      hasPermission = true;
                  }
              })
            : (hasPermission = true);
    }

    return hasPermission;
};

const useCanSetPassword = () => {
    const { isAdmin, myAccountRoles } =
        useGetDataForFilterDropdowns().editingPrivileges;

    const canSetPassword = (
        generalRoles: string[],
        user: TenantManagementModelsUserManagementUserModelRead,
        validAppRoles: Roles[],
        accessibleAccounts: getApiTmAccountsResponse[],
        accountId: number,
    ) => {
        const unfilteredAccounts: AccountRole[] = getAccountRoles(
            user,
            validAppRoles,
            accessibleAccounts,
            accountId,
            true,
        );
        return (
            handleAdminRoles(generalRoles, isAdmin, false) ??
            handleFeedlotManager(
                unfilteredAccounts,
                myAccountRoles,
                generalRoles,
                true,
            )
        );
    };

    return { canSetPassword };
};

const useIsEditable = () => {
    const { isAdmin, myAccountRoles } =
        useGetDataForFilterDropdowns().editingPrivileges;

    const isEditable = (
        generalRoles: string[],
        user: TenantManagementModelsUserManagementUserModelRead,
        validAppRoles: Roles[],
        accessibleAccounts: getApiTmAccountsResponse[],
        accountId: number,
    ) => {
        const unfilteredAccounts: AccountRole[] = getAccountRoles(
            user,
            validAppRoles,
            accessibleAccounts,
            accountId,
            true,
        );
        return (
            handleAdminRoles(generalRoles, isAdmin, true) ??
            handleFeedlotManager(
                unfilteredAccounts,
                myAccountRoles,
                generalRoles,
                false,
            )
        );
    };

    return { isEditable };
};

export const useGetUsers = ({
    appDropdownOptionsForModal,
    accessibleAccounts,
}: {
    appDropdownOptionsForModal: AppDropdownOptionForModal[];
    accessibleAccounts: getApiTmAccountsResponse[];
}) => {
    const { userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const { data, isFetching } = usersApi.useGetApiTmUsersOdataInfScrollQuery(
        useGetUserManagementUsersQueryArgs(),
    );
    const { canSetPassword } = useCanSetPassword();
    const { isEditable } = useIsEditable();

    const formatUserData = (): IUser[] => {
        if (data) {
            let validAppRoles: Roles[] = [];
            if (
                userManagementFilter.app &&
                userManagementFilter.app in AppDropdownOptionForFilter
            ) {
                validAppRoles =
                    RolesByAppDropdownOptionForFilter[userManagementFilter.app];
            } else {
                validAppRoles = appDropdownOptionsForModal
                    .map(
                        app =>
                            RolesAndScopesByAppDropdownOptionForModal[app]
                                .roles,
                    )
                    .flat();
            }

            const formattedUsers =
                data.map(user => {
                    const generalRoles = getGeneralRoles(user, validAppRoles);
                    const accountsAndRoles = getAccountRoles(
                        user,
                        validAppRoles,
                        accessibleAccounts,
                        +userManagementFilter.accountId,
                        false,
                    );
                    const accountRoles = accountsAndRoles?.map(
                        accountRole =>
                            `${accountRole.account} - ${accountRole.role}`,
                    );
                    const roles = [
                        ...generalRoles.map(role => spaceRoleName(role)),
                        ...accountRoles.map(role => spaceRoleName(role)),
                    ];
                    const apps = filterApps(user.scopes).map(app => {
                        return AppScopeToAppDropdownOptionForFilter[app.name];
                    });
                    return {
                        userId: user.userId ?? 0,
                        name: user.fullName ?? '',
                        email: user.username ?? '',
                        apps,
                        roles,
                        status: user.enabled
                            ? UserStatusEnum.Active
                            : UserStatusEnum.Inactive,
                        resetPassword: canSetPassword(
                            generalRoles,
                            user,
                            validAppRoles,
                            accessibleAccounts,
                            +userManagementFilter.accountId,
                        ),
                        editUser: isEditable(
                            generalRoles,
                            user,
                            validAppRoles,
                            accessibleAccounts,
                            +userManagementFilter.accountId,
                        ),
                    };
                }) ?? [];

            return formattedUsers;
        }
        return [];
    };

    return { users: formatUserData(), isFetching };
};

export const useGetDataForFilterDropdowns = (): {
    myAppsForModalDropdown: AppDropdownOptionForModal[];
    myAppsForFilterDropdown: AppDropdownOptionForFilter[];
    myAccounts: getApiTmAccountsResponse[];
    editingPrivileges: {
        isAdmin: boolean;
        isSuperAdmin: boolean;
        myAccountRoles: (string | null | undefined)[];
    };
} => {
    const allTenantAccounts = accountsApi.useGetApiTmAccountsQuery({}).data;
    const userId = useAuthContext().getUserId();
    const myUserData = usersApi.useGetApiTmUsersOdataQuery(
        +userId
            ? {
                  filter: `UserId eq ${userId}`,
                  include: 'Roles,Scopes,Accounts.Roles',
                  top: 1,
              }
            : skipToken,
    ).data?.[0];

    const myAppScopes: AppScope[] =
        myUserData?.scopes
            ?.filter(scope => scope.name && scope.name in AppScope)
            .map(scope => scope.name as AppScope) ?? [];

    const myAppsForFilterDropdown: AppDropdownOptionForFilter[] =
        myAppScopes.map(scope => AppScopeToAppDropdownOptionForFilter[scope]);

    const getMyAppsForDropdown = () => {
        const myAppsForModalDropdown: AppDropdownOptionForModal[] = [];

        Object.entries(RolesAndScopesByAppDropdownOptionForModal).forEach(
            ([appDropdownOption, { appScopes }]) => {
                if (
                    appScopes.every(appScope =>
                        myAppScopes.includes(appScope),
                    ) &&
                    appDropdownOption in AppDropdownOptionForModal
                ) {
                    myAppsForModalDropdown.push(
                        appDropdownOption as AppDropdownOptionForModal,
                    );
                }
            },
        );

        return myAppsForModalDropdown;
    };

    const getMyAccounts = () => {
        if (myUserData?.accounts?.length) {
            return (
                allTenantAccounts?.filter(account =>
                    myUserData.accounts?.some(
                        myAccount => myAccount.accountId === account.accountId,
                    ),
                ) ?? []
            );
        } else {
            return allTenantAccounts || [];
        }
    };

    const getEditingPrivileges = () => {
        const myRoles = myUserData?.roles?.map(role => role.name) || [];
        const isAdmin = myRoles.includes('Admin');
        const isSuperAdmin = myRoles.includes('SuperAdmin');
        const myAccountRoles =
            deepClone(myUserData)
                ?.accounts?.filter(account =>
                    account.roles?.some(role => role.name === 'FeedlotManager'),
                )
                ?.map(account => account.account?.name) || [];
        return { isAdmin, isSuperAdmin, myAccountRoles };
    };

    return {
        myAppsForModalDropdown: getMyAppsForDropdown(),
        myAppsForFilterDropdown,
        myAccounts: getMyAccounts(),
        editingPrivileges: getEditingPrivileges(),
    };
};
