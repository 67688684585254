import { ManageCommodities } from '../reducers/manageCommoditiesReducer';
import { AppStartListening } from '../../../../Redux/listenerMiddleware';

export const addManageCommoditiesListeners_EXAMPLE = (
    startAppListening: AppStartListening,
) => {
    startAppListening({
        actionCreator: ManageCommodities.actions.toggleCommoditiesUnit,
        effect: async (_, listenerApi) => {
            const unit =
                listenerApi.getState().manageCommodities.commoditiesUnit;
            localStorage.setItem('commoditiesUnit', unit);
        },
    });
};
