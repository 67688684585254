export const LabelConstant = {
    Location: {
        SELECT_LOCATION: 'Select Location',
        CREATE_LOCATION: 'Create Location',
    },
    Destination: {
        SELECT_DESTINATION: 'Select Destination',
        CREATE_DESTINATION: 'Create Destination',
    },
    TruckingCompany: {
        SELECT_COMPANY: 'Select Company',
        CREATE_COMPANY: 'Create Trucking Company',
    },
    Owner: { SELECT_OWNER: 'Select Owner', CREATE_OWNER: 'Create Owner' },
    Color: { SELECT_COLOR: 'Select Color', CREATE_COLOR: 'Create Color' },
    Sex: { SELECT_SEX: 'Select Sex', CREATE_SEX: 'Create Sex' },
    Breed: { SELECT_BREED: 'Select Breed', CREATE_BREED: 'Create Breed' },
    SlideUnit: {
        SELECT_SLIDE_UNIT: 'Select Slide Unit',
        CREATE_SLIDE_UNIT: 'Create Slide Unit',
    },
    FeedLots: {
        SELECT_FEEDLOT: 'Select FeedLot',
        CREATE_FEEDLOT: 'Create Feedlot',
    },
    FeeType: {
        SELECT_FEE_TYPE: 'Select Fee Type',
        TYPE_BRAND: 'Brands',
        TYPE_CATTLE: 'Cattle',
        TYPE_COMMISSION: 'Commission',
        TYPE_FREIGHT: 'Freight',
        TYPE_MISCELLANEOUS: 'Miscellaneous',
        TYPE_CVI: 'CVI',
    },
    FeeRateType: {
        SELECT_RATE_TYPE: 'Select Rate Type',
        TYPE_CWT: 'CWT',
        TYPE_PER_HD_CT: 'Per Hd Ct',
        TYPE_PER_MILE: 'Per Mile',
        TYPE_PERCENTAGE: 'Percentage',
        TYPE_FLAT: 'Flat',
    },
    Buyer: { SELECT_BUYER: 'Select Buyer', CREATE_BUYER: 'Create Buyer' },
    SecondBuyer: {
        SELECT_BUYER: 'Select Second Buyer',
        CREATE_BUYER: 'Create Second Buyer',
    },
    KillGroup: {
        SELECT_KILL_GROUP: 'Select Kill Group',
        CREATE_KILL_GROUP: 'Create Kill Group',
    },
    Payee: {
        SELECT_PAYEE: 'Select Payee',
        CREATE_PAYEE: 'Create Payee',
    },
    Languages: { ENGLISH: 'en', SPANISH: 'es' },

    en: {
        diagnoses: { SELECT_A_DIAGNOSIS: 'Select A Diagnosis' },
        lot: { SELECT_LOT: 'Select Lot' },
        owner: { CREATE_OWNER: 'Create Owner' },
        color: { CREATE_COLOR: 'Create Color' },
        buyer: { CREATE_BUYER: 'Create Buyer' },
        secondBuyer: { CREATE_BUYER: 'Create Second Buyer' },
        sex: { CREATE_SEX: 'Create Sex' },
        breed: { CREATE_BREED: 'Create Breed' },
        slideUnit: { CREATE_SLIDE_UNIT: 'Create Slide Unit' },
    },
    es: {
        diagnoses: { SELECT_A_DIAGNOSIS: 'Seleccione un diagnóstico' },
        lot: { SELECT_LOT: 'Seleccionar lote' },
    },
};

export const receiveCattleLabelConstants = {
    Owner: { SELECT_OWNER: 'Select Owner', CREATE_OWNER: 'Create Owner' },
    Breed: { SELECT_BREED: 'Select Sex', CREATE_BREED: 'Create Breed' },
    Color: { SELECT_COLOR: 'Select Color', CREATE_COLOR: 'Create Color' },
    Sex: { SELECT_SEX: 'Select Sex', CREATE_OWNER: 'Create Sex' },
    Lot: { SELECT_LOT: 'Select Lot', CREATE_LOT: 'Create Lot' },
    Pen: { SELECT_PEN: 'Select Pen' },
    Buyer: { SELECT_BUYER: 'Select Buyer', CREATE_BUYER: 'Create Buyer' },
    BuyLocation: {
        SELECT_BUYER_LOCATION: 'Select Location',
        CREATE_BUYER_LOCATION: 'Create Buy Location',
    },
    CattleType: {
        SELECT_CATTLE_TYPE: 'Select Breed',
        CREATE_CATTLE_TYPE: 'Create cattle type',
    },
    CattleSex: {
        SELECT_CATTLE_SEX: 'Select Sex',
        STEER: 'Steer',
        HEIFER: 'Heifer',
        BULL: 'Bull',
        COW: 'Cow',
        MIXED: 'Mixed',
    },
    Feedlot: { SELECT_FEEDLOT: 'Select Feedlot' },
};

export const rationLabelConstants = {
    Rations: { SELECT_RATION: 'Select Ration Version' },
    Owner: { SELECT_OWNER: 'Select Owner', CREATE_OWNER: 'Create Owner' },
    Ingredients: { SELECT_INGREDIENTS: 'Select Feed Type' },
    Lot: { SELECT_LOT: 'Select Lot', CREATE_LOT: 'Create Lot' },
    Pen: { SELECT_PEN: 'Select Pen' },
    Buyer: { SELECT_BUYER: 'Select Buyer', CREATE_BUYER: 'Create Buyer' },
    BuyLocation: {
        SELECT_BUYER_LOCATION: 'Select Buy Location',
        CREATE_BUYER_LOCATION: 'Create Buy Location',
    },
    CattleType: {
        SELECT_CATTLE_TYPE: 'Select Cattle Type',
        CREATE_CATTLE_TYPE: 'Create cattle type',
    },
    CattleSex: {
        SELECT_CATTLE_SEX: 'Select Sex',
        STEER: 'Steer',
        HEIFER: 'Heifer',
        BULL: 'Bull',
        COW: 'Cow',
        MIXED: 'Mixed',
    },
    Feedlot: { SELECT_FEEDLOT: 'Select Feedlot' },
    FeedType: {
        SELECT_FEE_TYPE: 'Select Feed Type',
        TYPE_BRAND: 'Hops',
        TYPE_CATTLE: 'Corn',
        TYPE_COMMISSION: 'Onions',
        TYPE_MISCELLANEOUS: 'Miscellaneous',
    },
};

export const feedCallLabelConstants = {
    PenType: {
        SELECT_PEN_TYPE: 'Select Pen',
        TYPE_BRAND: 'Pen 001',
        TYPE_CATTLE: 'Pen 002',
        TYPE_COMMISSION: 'Pen 003',
        TYPE_FREIGHT: 'Pen 004',
        TYPE_MISCELLANEOUS: 'others',
    },
};

export const shipCattleLabelConstants = {
    BuyLocation: {
        SELECT_LOCATION: 'Select Destination',
        CREATE_LOCATION: 'Create Destination',
    },
    owner: {
        SELECT_OWNER: 'Select Owner',
    },
    Lots: { SELECT_LOTS: 'Select Lot' },
    Eid: {
        SELECT_EID: 'Select EID',
        CREATE_EID: 'Create EID',
    },
    AnimalId: {
        SELECT_ANIMAL_ID: 'Select Animal ID',
        CREATE_ANIMAL_ID: 'Create Animal ID',
    },
    CattleDiedReasonId: {
        SELECT_REASON: 'Select Reason',
        CREATE_REASON: 'Create Reason',
    },
    Pens: { SELECT_PENS: 'Select Pen' },
    Sex: {
        SELECT_SEX: 'Select Sex',
    },
    CattleType: {
        SELECT_CATTLETYPE: 'Select CattleType',
    },
};

export const manualFeedEntryLabelConstants = {
    Ration: { SELECT_RATION: 'Select Ration' },
    Pen: { SELECT_PEN: 'Select Pen' },
};

export const medicalLotConstants = {
    MedicalLot: {
        SELECT_MEDICAL_LOT: 'Select Medical Lot',
        SELECT_MEDICAL_LOTID: 'selectMedicalLot',
        CREATE_MEDICAL_LOT: 'Create Medical Lot',
        CREATE_MEDICAL_LOTID: 'createMedicalLot',
    },
    en: {
        MedicalLotModalHeaders: {
            CREATE_MEDICAL_LOT: 'Create Medical Lot',
        },
        MedicalLotModalForm: {
            MEDICAL_ITEM: 'Medical Item *',
            MEDICAL_ITEM_LOT_ID: 'Medical Item Lot # *',
        },
    },
    es: {
        MedicalLotModalHeaders: {
            CREATE_MEDICAL_LOT: 'Crear lote médico',
        },
        MedicalLotModalForm: {
            MEDICAL_ITEM: 'Artículo médico *',
            MEDICAL_ITEM_LOT_ID: 'Lote de artículos médicos # *',
        },
    },
};

export const containerSizeConstants = {
    ContainerSize: {
        SELECT_AN_OPTION: 'Select an Option',
        SELECT_AN_OPTIONID: 'selectAnOption',
        CREATE_CONTAINER_SIZE: 'Create Container Size',
        CREATE_NEW_CONTAINER_SIZE: 'Create New Container Size',
        CREATE_CONTAINER_SIZEID: 'createContainerSize',
        CONTAINER_SIZE: 'Container Size',
    },
};

export const feedStatusConstant = {
    Rations: { ALL_RATIONS: 'All Rations' },
    Feedings: { ALL_FEEDINGS: 'All Feedings' },
    Drivers: { ALL_DRIVERS: 'All Drivers' },
    Pens: { ALL_PENS: 'All Pens' },
};

export const yardLayoutConstant = {
    Lot: { SELECT_LOT: 'All Lots' },
    Ration: { SELECT_RATION: 'All Rations' },
    Owner: { SELECT_OWNER: 'All Owners' },
};
