import { ICsvData } from './../../utils/Interface/CommonInterface';
import {
    IInventory,
    IMedicalInventoryAdjustmentHistoryFilter,
    IInventoryLots,
    INewInventory,
    IMedicalInventoryFilter,
} from '../../utils/Interface/HealthSection/InventoryInterface';
import {
    IContainerSize,
    IMedicalLot,
} from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { medicalLotConstants } from '../../utils/LabelConstant';
import dayjs from '../../utils/dayjs';

export const MEDICAL_INVENTORY_INITIAL_STATE = {
    medicalInventory: [] as IInventory[],
    adjustmentInventory: [] as IInventory[],
    completeMedicalInventory: [] as ICsvData[],
    completeAdjustmentHistory: [] as ICsvData[],
    createMedicalInventory: {
        medicalItemId: '',
        medicalInventoryActionType: 'Add',
        amount: 0,
        lotId: '',
        actionDate: dayjs(),
    } as INewInventory,
    medicalLots: [
        {
            medicalItemId: medicalLotConstants.MedicalLot.SELECT_MEDICAL_LOT,
            medicalLotId: medicalLotConstants.MedicalLot.SELECT_MEDICAL_LOTID,
            lotAmount: 0,
        },
        {
            medicalItemId: medicalLotConstants.MedicalLot.CREATE_MEDICAL_LOT,
            medicalLotId: medicalLotConstants.MedicalLot.CREATE_MEDICAL_LOTID,
            lotAmount: 0,
        },
    ] as IMedicalLot[],
    inventoryLots: [
        { name: medicalLotConstants.MedicalLot.SELECT_MEDICAL_LOT, lotId: '' },
        {
            name: medicalLotConstants.MedicalLot.CREATE_MEDICAL_LOT,
            lotId: medicalLotConstants.MedicalLot.CREATE_MEDICAL_LOTID,
        },
    ] as IInventoryLots[],
    containerSizes: [] as IContainerSize[],
    adjustmentHistoryFilter: {
        adjustmentHistoryFromDate: '',
        adjustmentHistoryToDate: '',
        medicalName: '',
        include:
            'medicalItem,MedicalItem.MedicalItemVersions,medicalItem.MedicalItemSku',
        actionType: 'Adjust',
        limit: 20,
        offset: 0,
    } as IMedicalInventoryAdjustmentHistoryFilter,
    medicalInventoryFilter: {
        medicalInventoryFromDate: '',
        medicalInventoryToDate: '',
        medicalItemName: '',
        include:
            'medicalItem,medicalItem.MedicalItemVersions,medicalItem.MedicalItemSku',
    } as IMedicalInventoryFilter,
    isLoadingMedicalInventory: false,
    submitPending: false,
};
