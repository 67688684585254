import { AppDropdownOptionForFilter } from '../../../../../common/modules/auth/constants/appDropdownOption';
import { RolesByAppDropdownOptionForFilter } from '../../../../../common/modules/auth/constants/roles';
import {
    IAdditionalRole,
    IUserManagementInitialState,
    UserStatusEnum,
} from '../interfaces/UserManagementInterface';

export const USER_MANAGEMENT_INITIAL_STATE: IUserManagementInitialState = {
    userManagementFilter: {
        limit: 20,
        offset: 0,
        name: '',
        email: '',
        accountId: 0,
        role: '',
        app: AppDropdownOptionForFilter['Select App'],
        status: UserStatusEnum.All,
    },
    users: [],
    feedlots: [],
    roles: RolesByAppDropdownOptionForFilter[
        AppDropdownOptionForFilter['Select App']
    ],
    apps: [],
};

export const additionalRoleInitialState: IAdditionalRole = {
    app: 'Select an App',
    accountId: 0,
    role: 'Select a Role',
};
